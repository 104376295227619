import { Tribe } from "@/Component/Index/Events/tribe.schema";
import GradientProfile from "../../Component/shared/GradientProfile";

const ProfileGrid = ({ tribe }: { tribe: Tribe }) => {
  return (
    <div className="relative ">
      <div className="flex flex-wrap gap-1 mt-4">
        {tribe.tribeUsers?.map((member, index) => (
          <div
            key={member._id || index}
            className="text-center"
          >
            <div className="text-white">
              {
                member.user?.profile_pic ? ( 
                  <img
                  src={member.user.profile_pic || "/api/placeholder/40/40"}
                  alt={`Member ${index + 1}`}
                  className="rounded-full w-10 h-10 object-cover border-2 border-white"
                  />

                ) : (
                  <GradientProfile
                    size="d"
                    text={member.user?.first_name.slice(0, 1) ?? ""}
                  />

                )
              }
            </div>
            <div className="text-primary-200 font-medium text-sm mt-2">
              {member.user?.first_name}
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileGrid;
