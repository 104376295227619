import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ImageIcon } from "lucide-react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import { Gif01Icon } from "hugeicons-react";

// Initialize GIPHY client
const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY);

const GiphySelector = ({ onGifSelect }: { onGifSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const fetchGifs = (offset) => {
    return searchTerm
      ? gf.search(searchTerm, { offset, limit: 10 })
      : gf.trending({ offset, limit: 10 });
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <button className="h-10 w-10 inline-flex items-center justify-center bg-primary-900 rounded-full border-primary-900  focus:outline-none focus:ring-2 ">
          <Gif01Icon className="text-primary-600" />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="rounded-lg p-4 w-[520px] bg-primary-900 shadow-lg "
          sideOffset={5}
        >
          <div className="space-y-4">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search GIFs..."
              className="w-full px-3 bg-primary-800 text-primary-300 h-8 rounded-md focus:outline-none focus:ring-2"
            />

            <div className="h-[400px] overflow-y-auto overflow-x-hidden">
              <Grid
                key={searchTerm}
                onGifClick={(gif, e) => {
                  e.preventDefault();
                  onGifSelect(gif);
                  setIsOpen(false);
                }}
                fetchGifs={fetchGifs}
                className="overflow-x-hidden"
                width={472}
                columns={3}
                gutter={10}
                noLink={true}
              />
            </div>

            <div className="text-xs text-primary-500 text-center">
              Powered by GIPHY
            </div>
          </div>

          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default GiphySelector;
