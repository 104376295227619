import dayjs from "dayjs";
import React from "react";

interface Props {
  startDate: string;
}

function EventCardCountdown(props: Props) {
  const {startDate} = props;

  const diff = dayjs(startDate).diff(dayjs(), 'hour');
  const diffMinutes = dayjs(startDate).diff(dayjs(), 'minute');
  const daysLeft = Math.floor(diff / 24);
  const hoursLeft = diff % 24;


  return (
    <div className="event-card-countdown">
      <div className="event-card-countdown-section">
        <span className="event-card-countdown-value">{daysLeft}</span>
        <span className="event-card-countdown-label">{daysLeft === 1 ? "Day" : "Days"}</span>
      </div>
      <div className="event-card-countdown-section">
        <span className="event-card-countdown-value">{hoursLeft < 1 && daysLeft === 0 ? diffMinutes : hoursLeft}</span>
        <span className="event-card-countdown-label">{hoursLeft < 1 && daysLeft === 0 ? "Minutes" : "Hours"}</span>
      </div>
    </div>
  );
}

export default EventCardCountdown;
