import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openPhoneSignIn } from "../redux/Reducers/modalReducer";
import WelcomeLogo from "../assets/img/WelcomeLogo.svg";
import Fb from "../assets/img/Facebook.svg";
import Apple from "../assets/img/Apple.svg";
import Google from "../assets/img/Google.svg";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUpWithPhone = () => {
    dispatch(openPhoneSignIn());
  };

  const handleGoogleSignup = () => {
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/google/signup`;
  };

  const handleAppleSignup = () => {
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/apple/signup`;
  };

  const handleFacebookSignup = () => {
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/facebook/signup`;
  };

  return (
    <div className="min-h-screen w-full">
      <div className="relative h-screen md:h-[90vh]">
        {/* Video Background */}
        <div className="absolute inset-0">
          <div className="bg-black/70 absolute inset-0 rounded-md overflow-hidden" />
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover rounded-md"
          >
            <source
              src="https://tribe-in.com/video/Loginvideo.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        {/* Content Overlay */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full px-4">
          <div className="max-w-md w-full space-y-8">
            {/* Logo */}
            <div className="flex justify-center">
              <img src={WelcomeLogo} alt="WelcomeLogo" className="h-12" />
            </div>

            {/* Tagline */}
            <div className="text-center text-white">
              <p className="text-lg font-medium">
                Connect. Engage. Experience Live Events Together.
              </p>
            </div>

            {/* Sign up buttons */}
            <div className="space-y-4">
              <button
                onClick={handleAppleSignup}
                className="w-full flex items-center justify-center px-4 py-2 bg-white rounded-md hover:bg-gray-50 transition-colors"
              >
                <img src={Apple} alt="apple" className="h-5 w-5 mr-3" />
                <span className="font-medium">Sign up With Apple</span>
              </button>

              <button
                onClick={handleGoogleSignup}
                className="w-full flex items-center justify-center px-4 py-2 bg-white rounded-md hover:bg-gray-50 transition-colors"
              >
                <img src={Google} alt="Google" className="h-5 w-5 mr-3" />
                <span className="font-medium">Sign up With Google</span>
              </button>

              <button
                onClick={handleFacebookSignup}
                className="w-full flex items-center justify-center px-4 py-2 bg-white rounded-md hover:bg-gray-50 transition-colors"
              >
                <img src={Fb} alt="Facebook" className="h-5 w-5 mr-3" />
                <span className="font-medium">Sign up With Facebook</span>
              </button>

              <button
                onClick={handleSignUpWithPhone}
                className="w-full flex items-center justify-center px-4 py-2 bg-white rounded-md hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium">Sign up With Phone Number</span>
              </button>
            </div>

            {/* Footer links */}
            <div className="text-center text-white space-y-2">
              <p className="text-sm">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-400 hover:text-blue-300">
                  Sign In
                </Link>
              </p>

              <p className="text-sm">
                By signing up for TribeIn, you agree to our{" "}
                <Link
                  to="/termsconditions"
                  className="text-blue-400 hover:text-blue-300"
                >
                  terms and conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
