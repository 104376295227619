import React, { useEffect } from "react";
import ChatList from "./_components/ChatList";
import MessageArea from "./_components/MessageArea/MessageArea";
import { useTribes } from "./hooks/useTribes";
import { Tribe } from "./hooks/tribes.schema";

interface Props {}

function Chat(props: Props) {
  const {} = props;
  const [selectedTribeId, setSelectedTribeId] = React.useState<string>("");
  const tribes = useTribes();

  useEffect(() => {

    if(!tribes.data) return;

    if(tribes?.data.docs.length > 0){ 
      setSelectedTribeId(tribes?.data.docs[0]?._id);

    }

  }, [tribes.data]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  const handleBack = () => {
    setSelectedTribeId("");
  };

  if (tribes.status === "error") {
    return <div className="">
      <div className="text-primary-100 text-lg">

      Error
      </div>
      <div className="text-primary-300"> 
        {tribes.error.message}
      </div>
    </div>;
  }

  if (tribes.status === "pending") {
    return <div className="text-white text-lg">Loading</div>;
  }

  if (tribes.data.docs.length === 0) {
    return (
      <div>
        <div className="text-white text-lg">You are not a part of any tribe</div>
      </div>
    );
  }

  const selectedTribe: Tribe =
    tribes.data.docs.find((item) => item._id === selectedTribeId) ??
    tribes.data.docs[0];

  return (
    <div className="fixed h-full inset-0 w-full lg:static">
      <div className="relative h-full w-full overflow-hidden">
        {/* Wrapper for both panels */}
        <div className="flex h-full w-full">
          {/* Chat List Panel */}
          <div
            className={`absolute lg:static w-full h-full min-w-full  lg:min-w-96 lg:w-96 max-w-96 transition-transform duration-300 ease-in-out ${
              selectedTribeId
                ? "lg:translate-x-0 -translate-x-full"
                : "translate-x-0"
            }`}
          >
            <ChatList
              selectedTribe={selectedTribe}
              tribes={tribes}
              onChatClick={(id) => setSelectedTribeId(id)}
            />
          </div>

          {/* Message Area Panel */}
          <div
            className={`lg:static h-full w-full    transition-transform duration-300 ease-in-out ${
              selectedTribeId
                ? "translate-x-0"
                : "translate-x-full lg:translate-x-0"
            }`}
          >
            <MessageArea
              onBackClick={handleBack}
              selectedTribe={selectedTribe}
              selectedTribeId={selectedTribeId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
