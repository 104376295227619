export const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-Binary", value: "nonbinary" },
  { label: "Prefer not to say", value: "preferNotToSay" },
];

export const MUSIC_GENRES = [
  { value: "bluegrass", label: "Bluegrass" },
  { value: "blues", label: "Blues" },
  { value: "christian", label: "Christian" },
  { value: "classical", label: "Classical" },
  { value: "country-music", label: "Country Music" },
  { value: "edm", label: "EDM" },
  { value: "folk", label: "Folk" },
  { value: "hip-hop-rap", label: "Hip Hop & Rap" },
  { value: "indie", label: "Indie" },
  { value: "jamband", label: "Jamband" },
  { value: "jazz", label: "Jazz" },
  { value: "kpop", label: "K-pop" },
  { value: "latin", label: "Latin" },
  { value: "metal", label: "Metal" },
  { value: "pop", label: "Pop" },
  { value: "punk", label: "Punk" },
  { value: "rhythm-and-blues-soul", label: "R&B / Soul" },
  { value: "reggae", label: "Reggae" },
  { value: "rock", label: "Rock" },
];

export const AGE_PREFERENCES = [
  { label: "25-34", value: 1 },
  { label: "35-44", value: 2 },
  { label: "45-54", value: 3 },
  { label: "55-64", value: 4 },
  { label: "65 or older", value: 5 },
];
export const GENDER_PREFERENCES = [
  { label: "Males only", value: "males" },
  { label: "Females only", value: "females" },
  { label: "Non-binary", value: "nonbinary" },
  { label: "Mixed gender", value: "mixed" },
];

export const ZODIAC_SIGNS = [
  { sign: "Capricorn", start: "01-01", end: "01-19" },
  { sign: "Aquarius", start: "01-20", end: "02-18" },
  { sign: "Pisces", start: "02-19", end: "03-20" },
  { sign: "Aries", start: "03-21", end: "04-19" },
  { sign: "Taurus", start: "04-20", end: "05-20" },
  { sign: "Gemini", start: "05-21", end: "06-20" },
  { sign: "Cancer", start: "06-21", end: "07-22" },
  { sign: "Leo", start: "07-23", end: "08-22" },
  { sign: "Virgo", start: "08-23", end: "09-22" },
  { sign: "Libra", start: "09-23", end: "10-22" },
  { sign: "Scorpio", start: "10-23", end: "11-21" },
  { sign: "Sagittarius", start: "11-22", end: "12-21" },
  { sign: "Capricorn", start: "12-22", end: "12-31" },
];

export const CONFIRMATION_TYPES = {
  LOGOUT: "logout",
  HIDEMYLOCATION: "hidemylocation",
};

export const DATE_RANGE = [
  { label: "All", value: "all" },
  { label: "Tonight", value: "tonight" },
  { label: "Tomorrow", value: "tomorrow" },
  { label: "This Weekend", value: "thisWeekend" },
  { label: "Next Weekend", value: "nextWeekend" },
];

export const DEBOUNCE_TIME = 1000;

export const GIF_TABS = [
  { eventKey: "popular", title: "Popular" },
  { eventKey: "laughter", title: "Laughter" },
  { eventKey: "sad", title: "Sad" },
  { eventKey: "love", title: "Love" },
  { eventKey: "music", title: "Music" },
  { eventKey: "sports", title: "Sports" },
  { eventKey: "expressive", title: "Expressive" },
];
