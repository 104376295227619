import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {
  ArrowDown01Icon,
  BulbIcon,
  Calendar02Icon,
  CreditCardIcon,
  File01Icon,
  Home02Icon,
  Logout01Icon,
  Setting07Icon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
} from "hugeicons-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/img/LogoTxt.svg";
import { CONFIRMATION_TYPES } from "../../helpers/constant.js";
import {
  openConfirmationModal,
  openLogin,
  openSignup,
} from "../../redux/Reducers/modalReducer.js";
import { Button } from "../shared/Button.tsx";
import GradientProfile from "../shared/GradientProfile.tsx";
import { NAVBAR_CLASS } from "../../helpers/constants.ts";

export function ProfileDropdown() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUserLogedIn, user } = useSelector((state) => state?.user);
  const handleLogout = () => {
    const data = {
      mode: CONFIRMATION_TYPES.LOGOUT,
      message: "Are you sure you want to logout?",
    };
    dispatch(openConfirmationModal(data));
  };

  const menuItems = [
    {
      label: "Home",
      path: "/",
      icon: Home02Icon,
      isMainItem: false,
      requiresAuth: false,
    },
    {
      label: "Tribes",
      path: "/chat",
      requiresAuth: true,
      icon: UserGroupIcon,
      isMainItem: true,
    },
    {
      label: "My events",
      path: "/Myevents",
      icon: Calendar02Icon,
      requiresAuth: true,
      isMainItem: true,
    },
    {
      label: "Payment",
      path: "/payment",
      icon: CreditCardIcon,
      requiresAuth: true,
      isMainItem: false,
    },
    {
      label: "Profile",
      path: "/profile",
      icon: UserCircleIcon,
      requiresAuth: true,
      isMainItem: true,
    },
    {
      label: "Settings",
      path: "/settings",
      icon: Setting07Icon,
      requiresAuth: true,
      isMainItem: false,
    },
    {
      label: "Suggest a feature",
      path: "/suggestfeature",
      icon: BulbIcon,
      requiresAuth: true,
      isMainItem: false,
    },
    {
      label: "Terms and conditions",
      path: "/termsconditions",
      icon: File01Icon,
      requiresAuth: false,
      isMainItem: false,
    },
  ] as const;

  const mainItems = menuItems.filter((item) => item.isMainItem);

  const isChat = location.pathname.includes("/chat");

  

  return (
    <>
      <div
        id={NAVBAR_CLASS}
        className={`flex top-0 left-0 bg-primary-900  backdrop-blur	 bg-opacity-50  z-40 w-screen items-center justify-between  py-3 ${
          isChat ? "lg:block hidden static" : "fixed"
        }`}
      >
        
        <div className="max-w-[95%] mx-auto w-full flex items-center justify-between ">
          <div className="">
            <Link className="" to={"/"}>
              <img
                src={LogoIcon}
                alt="logo-icon"
                className="logo max-w-[8rem]"
              />
              {/*      <div className="text-primary-300 text-[12px] font-medium md:hidden">
                Experience Live Events{" "} <br />
                <span className="text-utility-pink font-semibold">Together</span>
              </div> */}
            </Link>
          </div>

          {!user ? (
            <div className="hero   text-center hidden md:block">
              <div className="text-white lg:text-2xl font-semibold">
                Experience Live Events{" "}
                <span className="text-utility-pink font-semibold">
                  Together
                </span>
              </div>
              <div className="text-primary-200 text-sm">
                Join Group Chats | Find Your Tribe
              </div>
            </div>
          ) : (
            <NavigationMenu.Root className="relative z-[1]">
              <NavigationMenu.List className="flex-row items-center hidden md:flex">
                {mainItems.map((item) => (
                  <NavigationMenu.Item
                    className="hover:scale-[105%] transition-all rounded-lg cursor-pointer hover:bg-utility-blue px-4 py-2"
                    key={item.path}
                  >
                    <NavigationMenu.Link
                      className={`${
                        location.pathname === item.path
                          ? "text-primary-200"
                          : "text-primary-100"
                      } text-xs h-full w-full font-medium hover:text-primary-200 transition-colors flex items-center gap-2 `}
                      href={item.path}
                    >
                      <item.icon className="text-2xl  scale-110" />
                      {item.label}
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                ))}
              </NavigationMenu.List>
            </NavigationMenu.Root>
          )}

          <DropdownMenu.Root>
            {user ? (
              <div className="flex items-center gap-2">
                <div className="border-r border-primary-800 pr-4">
                  {/*                 <ProfileCompletion user={user} />
                   */}{" "}
                </div>
                <DropdownMenu.Trigger asChild>
                  <button className="focus:outline-none">
                    <div className="flex items-center">
                      {user?.profile_pic ? (
                        <img
                          src={user?.profile_pic}
                          alt="profile"
                          className="w-8 h-8 rounded-full object-cover border-primary-800 border"
                        />
                      ) : (
                        <div className="flex-shrink-0 ">
                          <GradientProfile
                            text={user?.first_name?.slice(0, 1) ?? "U"}
                          />
                        </div>
                      )}
                      <span className="ml-2 text-white font-semibold text-sm max-w-[6rem] text-ellipsis  overflow-hidden ">
                        {user?.first_name ?? "User"}
                      </span>
                      <ArrowDown01Icon className="text-primary-400 w-6 ml-2" />
                    </div>
                  </button>
                </DropdownMenu.Trigger>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => {
                    dispatch(openLogin());
                  }}
                  variant={"outline"}
                  className="h-full"
                >
                  Sign in
                </Button>
                <Button
                  onClick={() => {
                    dispatch(openSignup());
                  }}
                  variant={"default"}
                  className="h-full"
                >
                  Sign up
                </Button>
              </div>
            )}

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                align="end"
                className="min-w-[10rem] data-[state=open]:animate-in overflow-hidden bg-primary-900 rounded-md shadow-lg  z-[99999999999] ring-0 "
              >
                {menuItems.map((item) => (
                  <DropdownMenu.Item
                    key={item.path}
                    className=" flex outline-none ring-0 focus:ring-0 items-center cursor-pointer hover:bg-primary-800"
                  >
                    <Link
                      to={item.path}
                      className="text-white py-3 ring-0 px-3 font-medium w-full h-full flex items-center gap-1"
                    >
                      <item.icon
                        className="w-5 h-5 mr-3 text-primary-500"
                        aria-hidden="true"
                      />
                      <div className="text-sm w-full text-primary-200">
                        {item.label}
                      </div>
                    </Link>
                  </DropdownMenu.Item>
                ))}

                <DropdownMenu.Separator className="my-1 border-t border-primary-800" />

                {user ? (
                  <DropdownMenu.Item>
                    <button
                      onClick={handleLogout}
                      className="group flex font-medium items-center w-full px-3 py-2 text-sm text-primary-300 hover:bg-primary-800"
                    >
                      <Logout01Icon
                        className="w-5 h-5 mr-3 text-primary-400 group-hover:text-primary-300"
                        aria-hidden="true"
                      />
                      Logout
                    </button>
                  </DropdownMenu.Item>
                ) : (
                  <DropdownMenu.Item>
                    <button
                      onClick={() => dispatch(openLogin())}
                      className="group flex items-center w-full px-4 py-2 text-sm text-primary-300 hover:bg-primary-800"
                    >
                      <UserIcon
                        className="w-5 h-5 mr-3 text-primary-400 group-hover:text-primary-300"
                        aria-hidden="true"
                      />
                      Log In
                    </button>
                  </DropdownMenu.Item>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>

      <div className="mb-24 hidden"></div>
    </>
  );
}
