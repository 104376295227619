import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import Instagram from "../../assets/img/Instagram.svg";
import Facebook from "../../assets/img/Facebook.svg";
import Ticktok from "../../assets/img/Ticktok.svg";
import Snapchat from "../../assets/img/Snapchat.svg";
import Twitter from "../../assets/img/Twitter.svg";
import Twitchaccount from "../../assets/img/Twitchaccount.svg";
import Closecircle from "../../assets/img/Closecircle.svg";
import { useDispatch } from "react-redux";
import {
  closeSocialConnectionModal,
  openCaptchaModal,
  openIntegrationModal,
  openSocialConnectionModal,
} from "../../redux/Reducers/modalReducer";
import { addUserDetails } from "../../api/api";
import { toast } from "react-toastify";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";
import { Connecticon } from "../../Component/utils/icon";

const Socialmedia = (props) => {
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const response = await addUserDetails({ is_social_accounts_added: true });
    if (response.data.is_music_payment_account_added === true) {
      if (response.data.is_captacha_verified === true) {
        toast.success(response.message);
        dispatch(setUser(response.data.user));
        dispatch(closeSocialConnectionModal());
        dispatch(setUserLogin());
      } else {
        dispatch(closeSocialConnectionModal());
        dispatch(openCaptchaModal());
      }
    } else {
      dispatch(closeSocialConnectionModal());
      dispatch(openIntegrationModal());
    }
  };
  const handleSkip = async () => {
    const response = await addUserDetails({ is_social_accounts_skipped: true });
    dispatch(closeSocialConnectionModal());
    dispatch(openIntegrationModal());
  };
  const back = () => {
    dispatch(closeSocialConnectionModal());
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile completeyour_profile_photo"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <div className="flex_login_container">
          <div className="login_video">
            <video autoPlay loop muted playsInline="true" className="w-full h-full object-cover">
              <source src="https://tribe-in.com/video/SocialMediaVideo.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="welcome_logo">
            <div className="completeyour_profile">
              <h2 onClick={back} className="back_arrow_signin">
                <div className="back_profile_icon d-none">
                  <img src={Backicon} alt="logo-icon" className="Backicon" />
                </div>
                Complete your profile
              </h2>
            </div>
            <div className="personal_info">
              <h2>
                <span>4</span> Social Media{" "}
              </h2>
              <ProgressBar now={80} />;
            </div>
            <Form>
              <div className="social_media_group">
                <ul className="social_media_list">
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Instagram} alt="Instagram" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Instagram account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>
                          Connect{" "}
                          <div className="connect_icon">
                            <Connecticon />
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Facebook} alt="Facebook" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Facebook account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>
                          Connect{" "}
                          <div className="connect_icon">
                            <Connecticon />
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Ticktok} alt="Ticktok" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Tik Tok account</p>
                        </div>
                      </div>

                      <div className="connect_txt">
                        <span>
                          Connect{" "}
                          <div className="connect_icon">
                            <Connecticon />
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Snapchat} alt="Snapchat" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Snapchat account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>
                          Connect{" "}
                          <div className="connect_icon">
                            <Connecticon />
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Twitter} alt="Twitter" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your X account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>
                          Connect{" "}
                          <div className="connect_icon">
                            <Connecticon />
                          </div>{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Twitchaccount} alt="Twitchaccount" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Twitch account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <button className="close_btn">
                          <img src={Closecircle} alt="Closecircle" />
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="completeyour_profile_btn profile_photo_btn">
                <Button variant="outline-light" onClick={handleSkip}>
                  Skip
                </Button>
                <Button variant="secondary" onClick={handleSubmit}>
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Socialmedia;
