import dayjs from "dayjs";
import {
  Calendar03Icon,
  PinLocation01Icon,
  Wallet03Icon,
} from "hugeicons-react";
import React from "react";
import { Link } from "react-router-dom";
import { Event } from "./event.schema";
import "./event.scss";
import EventCardCountdown from "./EventCardCountdown.tsx";
import { isDate } from "moment";
import mixpanel from "mixpanel-browser";

type Props = {
  event: Event;
};

export const DAYJS_FORMAT = "ddd, MMM D, YYYY";

const EventCard = ({ event }: Props) => {
  function getLocation(venue: Event["event_venue"]) {
    if (venue?.city && venue?.state && venue?.name) {
      return `${venue.name} ${venue.city}, ${venue?.state}`;
    }

    if (venue?.city && venue?.name) {
      return `${venue.name} ${venue.city}`;
    }

    if (venue?.city) {
      return venue.city;
    }

    if (venue?.state && venue?.country) {
      return `${venue.state}`;
    }
  }

  if (event.event_name === "Apocalypse Zombie Land") {
  }

  const isMissingStartTime = event.event_start_date.includes("00:00:00");
  const localStartDate = dayjs(event.event_start_date).format(DAYJS_FORMAT);
  const utcStartDate = dayjs(event.event_start_date).utc().format(DAYJS_FORMAT);
  let startDate = isMissingStartTime ? utcStartDate : localStartDate;

  let endTime = undefined;
  if (event.event_end_date) {
    const isMissingEndTime = event.event_end_date.includes("00:00:00");
    const localEndDate = dayjs(event.event_end_date).format(DAYJS_FORMAT);
    const utcEndDate = dayjs(event.event_end_date).utc().format(DAYJS_FORMAT);
    endTime = isMissingEndTime ? utcEndDate : localEndDate;
  }

  const localEndDate = endTime;

  const endDate = !event.event_end_date ? undefined : localEndDate;

  const isWithinTwoWeeks = dayjs(event.event_start_date).isBefore(
    dayjs().add(14, "days")
  );
  const isInPast = dayjs(event.event_start_date).isBefore(dayjs());

  const firstTicketPrice = React.useMemo(() => {
    const firstTicket = event.tickets?.[0];
    const firstTicketType = firstTicket?.ticket_types?.[0];
    const price = firstTicketType?.price;

    return typeof price === "number" && !isNaN(price) ? price : null;
  }, [event.tickets]);

  function getGenreName(): string {
    if (!event.genre && typeof !event.sport) {
      return "";
    }

    const genre = event?.genre[0]?.name;
    const sport = event?.sport;

    const invalidNames = ["", "null", "undefined", "Undefined", "Null"];

    const genreType = event.event_type === "sports" ? sport : genre;

    if (typeof genreType === "string") {
      return invalidNames.includes(genreType) ? "" : genreType;
    } else {
      return "";
    }
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const arrays = Array.from({ length: 4 }, () => []);

  return (
    <div className="event-card ">
      {/* Wrap the content in a Link component */}
      <Link
        onClick={() => {
          mixpanel.track("Clicked event", event);
        }}
        to={`/event/${event._id}`}
        className="h-full  flex flex-col justify-between"
      >
        <div>
          <div className="event-card-image-wrap">
            {getGenreName() && (
              <div className="event-card-genre">{getGenreName()}</div>
            )}
            {isWithinTwoWeeks && !isInPast && (
              <EventCardCountdown startDate={event.event_start_date} />
            )}
            <img
              className="event-card__image"
              loading="lazy"
              alt="Event"
              src={event.banner_image}
            />
          </div>
          <div className="event-card-content">
            <div className="event-card-title text-sm md:text-base">
              {event.event_name}
            </div>
            <div className="event-card-text">
              <PinLocation01Icon /> {getLocation(event.event_venue)}
            </div>
            <div className="event-card-text">
              <Calendar03Icon />
              {startDate} {endDate ? `- ${endDate}` : undefined}
            </div>
            <div className="event-card-text">
              <Wallet03Icon />
              {firstTicketPrice ? (
                <span>From: {currencyFormatter.format(firstTicketPrice)}</span>
              ) : (
                <span>Check on Partner's Site</span>
              )}
            </div>
          </div>
        </div>
        {/*         <div className="flex pb-2 px-2 items-center">
          {arrays.map((_, index) => (
            <div className="h-6 w-6 -mr-2">
              <img
                className="w-full  h-full rounded-full"
                src="https://plus.unsplash.com/premium_photo-1670071482460-5c08776521fe?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
            </div>
          ))}
          <div className="ml-4">
            <div className="text-xs font-semibold text-primary-200 ">
              4 Tribes
            </div>
            <div className="text-xs font-medium text-primary-500">
              16 Attending
            </div>
          </div>
        </div> */}
      </Link>
    </div>
  );
};

export { EventCard };
