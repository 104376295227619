import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSignup } from "../redux/Reducers/modalReducer";
import Layout from "../Component/Layout/Layout";
import { logout } from "../redux/Reducers/userReducer";

function PublicRoutes() {
  let token = localStorage.getItem("token");

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default PublicRoutes;
