import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import Backicon from "../../assets/img/Backicon.svg";

import { getTribes } from "../../api/api";
import {
  closeTribe,
  openCreateTribe,
  openSkipTribeModal,
} from "../../redux/Reducers/modalReducer";
import { CircularImageLayout } from "./CircularImage";
import { MAX_TRIBE_USERS } from "./TribeInfo";
import { useJoinTribe } from "./useJoinTribe";
const Tribes = (props) => {
  const { data } = useSelector((state) => state?.model);
  const { user, preferences } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [tribe, setTribe] = useState([]);
  const [selectedTribeId, setSelectedTribeId] = useState(null);
  const userLocation = useSelector((state) => state.user.userLocation);
  const navigate = useNavigate();
  const eventId = data.eventId;
  const { data: tribeData, mutate, status } = useJoinTribe();

  // const [userTribeJoinedId, setUserTribeJoinedId] = useState(null);

  const handleSkip = () => {
    dispatch(openSkipTribeModal());
  };

  useEffect(() => {
    fetchAllTribes();
  }, [userLocation, eventId]);

  const fetchAllTribes = async () => {
    const radius = 10;

    const requestData = {
      event_id: eventId,
      search_query: "",
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      order: -1,
    };

    try {
      const response = await getTribes(requestData);
      console.log(response, "tribe response");
      setTribe(response.data.docs);
      if (response.data.docs.length > 0) {
        setSelectedTribeId(response.data.docs[0]._id);
      }
    } catch (error) {
      console.error("Error fetching tribes:", error);
    }
  };


  const handleCreateTribe = () => {
    dispatch(openCreateTribe(data));
    dispatch(closeTribe());
  };

  // const tribe_id = findTribeIdByUserId(tribe, user._id);

  const allTribesFull = tribe?.every(
    (tribe) => tribe?.tribeUsers?.length >= MAX_TRIBE_USERS
  );

  const canCreateNewTribe = tribe?.length === 0 || allTribesFull;

  return (
    <>
      <Modal
        //    show={isOpen}
        {...props}
        className="sign_in_modal signin_inner_modal categories_modal tribein_modal py-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="back_arrow_signin">
              <div className="back_profile_icon">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>{" "}
              Sign in
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <h4>TribeIn</h4>
          {tribe.length === 0 && (
            <CircularImageLayout
              pending={status === "pending"}
              onJoin={(tribeId) => {
                mutate({ tribeId });
              }}
              tribe={{}}
              testMode={true}
            />
          )}
          <div className="space-y-12">
            {tribe.map((tribeItem, index) => (
              <>
                <CircularImageLayout
                  pending={status === "pending"}
                  onJoin={(tribeId) => {
                    mutate({ tribeId });
                  }}
                  testMode={true}
                  tribe={tribeItem}
                />
                {/*      <TribeInfo
                      pending={status === "pending"}
                      onJoin={(tribeId) => {
                        mutate({ tribeId });
                      }}
                      tribe={tribeItem}
                    /> */}
              </>
            ))}
          </div>
          <div className="edit_your_age">
            {/*   <Link
              onClick={() =>
                dispatch(openUpdatePreferences({ eventId: data.eventId }))
              }
            >
              Edit your age and gender preferences
            </Link> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {canCreateNewTribe && (
            <div className="completeyour_profile_btn profile_photo_btn m-0">
              <Button onClick={handleCreateTribe} variant="outline-light">
                CREATE TRIBE
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tribes;
