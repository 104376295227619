import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import * as z from "zod";
import { SendMessages } from "../../../api/api";
import { useSocket } from "../../../context/SocketContext";
import MessageResponseSchema, {
  MessageSchema,
  MessageTribe,
  SenderSchema,
  MessageResponse,
} from "./messages.schema";
import { Tribe } from "./tribes.schema";

interface MessageData {
  sender_id: string;
  tribe_id: string;
  type: string;
  message: string;
}

interface MessageSenderProps {
  sender_id: string;
  tribe_id: string;
  tribeName: string;
  user: SenderSchema;
  tribe: MessageTribe;
}

export const useSendMessage = ({
  sender_id,
  tribe_id,
  tribeName,
  user,
  tribe,
}: MessageSenderProps) => {
  const socket = useSocket();
  const queryClient = useQueryClient();

  const { mutate: sendMessage } = useMutation({
    mutationKey: ["submit-message"],
    mutationFn: async ({
      newMessage,
      type,
    }: {
      newMessage: string;
      type?: "gif" | "text";
    }) => {
      const messageData: MessageData = {
        sender_id,
        tribe_id,
        type: type ?? "text",
        message: newMessage,
      };
      const response = await SendMessages(messageData);
      return response;
    },
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({ queryKey: ["messages", tribe_id] });

      const previousMessages = queryClient.getQueryData<
        InfiniteData<MessageResponse>
      >(["messages", tribe_id]);

      const optimisticMessage: MessageSchema = {
        _id: `temp-${Date.now()}`,
        sender_id,
        tribe_id,
        type: newMessage.type,
        message: newMessage.newMessage,
        read_by: [sender_id],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        sender: user,
        tribe,
      };

      queryClient.setQueryData<InfiniteData<MessageResponse>>(
        ["messages", tribe_id],
        (old) => {
          if (!old)
            return {
              pages: [
                {
                  docs: [optimisticMessage],
                  totalDocs: 1,
                  hasMore: false,
                  currentPage: 1,
                  totalPages: 1,
                  limit: 50,
                },
              ],
              pageParams: [1],
            };

          return {
            ...old,
            ...old.pages.slice(1),
            pages: [
              {
                ...old.pages[0],
                docs: [...old.pages[0].docs, optimisticMessage],
              },
            ],
          };
        }
      );

      return { previousMessages };
    },
    onSuccess: (data) => {
      socket.emit("send message", data);
    },
    onError: (_, __, context: any) => {
      queryClient.setQueryData(
        ["messages", tribe_id],
        context.previousMessages
      );
    },
  });

  return {
    sendMessage,
  };
};
