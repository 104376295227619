import { getToken } from "firebase/messaging";
import mixpanel from "mixpanel-browser";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { addFirebaseToken, verifyToken } from "../api/api";
import Hello from "../Component/Layout/Hello";
import Layout from "../Component/Layout/Layout";
import { messaging } from "../firebaseSetup";
import IndexModal from "../modals/IndexModal";
import Concerts from "../pages/Concerts";
import Festivals from "../pages/Festivals";
import Payment from "../pages/Payment";
import Searchempty from "../pages/searchempty";
import TempLogin from "../pages/temp.login";
import { openUpdateProfile } from "../redux/Reducers/modalReducer";
import { logout, setUser, setUserLogin } from "../redux/Reducers/userReducer";
import EnsureAccountVerification from "../Routes/EnsureAccountVerification";
import NotFound from "./NotFound";
import Protected from "./Protected";
import PublicRoutes from "./PublicRoutes";
import Chat from "../Component/Chat/Chat";

// import IPInfo from "ip-info-react";

// Use lazy for component imports
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Event = lazy(() => import("../pages/Event"));
const Fillter = lazy(() => import("../Component/fillter/fillter"));
const Myevent = lazy(() => import("../pages/Myevent"));
const Requestsuccessfullysubmitted = lazy(() =>
  import(
    "../Component/requestsuccessfullysubmitted/requestsuccessfullysubmitted"
  )
);
const Profile = lazy(() => import("../pages/profile"));
const Yourfeedback = lazy(() => import("../modals/yourfeedback/yourfeedback"));
const Jointribe = lazy(() => import("../pages/Jointribe"));
const Createtribetwo = lazy(() =>
  import("../modals/createtribe/createtribetwo")
);
const Createtribethree = lazy(() =>
  import("../modals/createtribe/createtribethree")
);
const Tribewill = lazy(() => import("../Component/Tribewill/tribewill"));
const Settings = lazy(() => import("../pages/settings"));
const Suggestionsfeatures = lazy(() => import("../pages/suggestionsfeatures"));
const Termscondition = lazy(() => import("../pages/termscondition"));
const Firstmessage = lazy(() => import("../pages/firstmessage"));
const Reportuser = lazy(() => import("../modals/reportuser/reportuser"));
const UserProfile = lazy(() => import("../pages/UserProfile"));

const AppRoutes = () => {
  const { isUserLogedIn, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      localStorage.setItem("notificationDenied", 0);
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        console.log("Token generated : ", token);
        await addFirebaseToken({ _id: user._id, firebase_token: token });
      } catch (error) {
        console.log("Error in requesting permission", error);
      }
    }

    // else if (permission === "denied") {
    //   if (localStorage.getItem("notificationDenied") != 1) {
    //     localStorage.setItem("notificationDenied", 1);
    //     alert("You denied for the notification");
    //   }
    // }
  }

  function validateUserProperties(user) {
    const requiredProperties = [
      "first_name",
      "last_name",
      "gender",
      "email",
      "dob",
    ];
    const missingProperties = [];

    for (const prop of requiredProperties) {
      if (
        !user.hasOwnProperty(prop) ||
        user[prop] === null ||
        user[prop] === undefined
      ) {
        missingProperties.push(prop);
      }
    }

    if (missingProperties.length === 0) {
      return { isValid: true, message: "All required properties are present." };
    } else {
      return {
        isValid: false,
        message: `Missing properties: ${missingProperties.join(", ")}`,
        missingProperties: missingProperties,
      };
    }
  }

  useEffect(() => {
    // just hacking together something to make auth work here......
    async function hello() {
      const searchParams = new URLSearchParams(window.location.search);
      const newToken = searchParams.get("token");
      if (newToken) {
        localStorage.setItem("token", newToken);
      }

      const tokenToBeUsed = localStorage.getItem("token");
      console.log(tokenToBeUsed, "here goes the token");

      if (!tokenToBeUsed) {
        return;
      }

      try {
        const response = await verifyToken({ token: tokenToBeUsed });
        if (response.data) {
          dispatch(setUser(response.data));
          dispatch(setUserLogin());
          const properties = validateUserProperties(response.data);
          if (!properties.isValid) {
            const name =
              response?.data?.first_name;
              console.log(name, "MIXPANEL NAME")
            mixpanel.identify(response.data._id);
            mixpanel.people.set({
              $name: name ?? "name not added",
              $email: response.data.email ?? "email not added",
            });
            dispatch(openUpdateProfile());
          }
        } else {
          dispatch(logout());
        }
      } catch (error) {
        console.log(error, "hereeee");
        /*         dispatch(logout()); */
      }
    }
    hello();
  }, [window.location]);

  useEffect(() => {
    if (isUserLogedIn && messaging !== null) {
      requestPermission();
    }
  }, [isUserLogedIn]);

  return (
    <>
      <IndexModal />
      <EnsureAccountVerification user={user} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route path="/test" element={<Hello />} />
          <Route element={<Protected />}>
            <Route path="Profile" element={<Profile />} />
            <Route path="Myevents" element={<Myevent />} />
            <Route exact path="myTribes" element={<Jointribe />} />
          <Route path="/chat" element={<Chat />} />
            <Route path="/user/profile/:user_id" element={<UserProfile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/suggestfeature" element={<Suggestionsfeatures />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/festivals" element={<Festivals />} />
          </Route>
          <Route exact path="/Reportuser" element={<Reportuser />} />
          <Route exact path="/Fillter" element={<Fillter />} />
          <Route exact path="/Tribewill" element={<Tribewill />} />
          <Route path="/firstmessage" element={<Firstmessage />} />
          <Route
            path="/Requestsuccessfullysubmitted"
            element={<Requestsuccessfullysubmitted />}
          />
          <Route path="/yourfeedback" element={<Yourfeedback />} />

          <Route path="*" element={<NotFound />} />
          <Route element={<PublicRoutes />}>
            <Route path="/concerts" element={<Concerts />} />
            <Route path="/event/:identifier" element={<Event />} />
            <Route path="/events" element={<Searchempty />} />
            <Route path="/termsconditions" element={<Termscondition />} />
            <Route path="/sign-up" element={<TempLogin />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
