import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Form from "react-bootstrap/Form";
import Suggestionlogo from "../../assets/img/Suggestionlogo.svg";
import { useSelector, useDispatch } from "react-redux";
import { pastSuggestion, inviteTribes, cretaeTribes } from "../../api/api";
import { toast, ToastContainer } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { useFormik } from "formik";
import * as Yup from "yup";
import Whitecorss from "../../assets/img/Whitecorss.svg";
import {
  closeCreateTribe,
  closeTribe,
  openPhoneBookModal,
} from "../../redux/Reducers/modalReducer";
import userprofileimage from "../../assets/img/profile-pic.svg";

const Createtribe = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [suggestion, setSuggestion] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [show, setShow] = useState(false);
  const [contactError, setContactError] = useState("");
  const { data } = useSelector((state) => state?.model);
  const isOpen = useSelector((state) => state.model.isopenCreateTribe);
  const eventId = data?.eventId; // Ensure eventId is properly accessed from data

  const [tribeLink, setTribeLink] = useState("");
  const [tribeCreated, setTribeCreated] = useState(false); // State to track tribe creation

  const fetchSuggestion = async () => {
    try {
      const response = await pastSuggestion(searchInput);
      if (response.statusCode === 200) {
        setSuggestion(response.data);
      }
    } catch (error) {
      console.error("Error fetching all suggestions:", error);
    }
  };

  const inviteMembers = async (tribeId) => {
    try {
      const validSuggestions = selectedSuggestions.filter(
        (suggestion) =>
          suggestion.phone_detail &&
          suggestion.phone_detail.country_code &&
          suggestion.phone_detail.phone_number
      );

      if (validSuggestions.length === 0) {
        throw new Error("Please select at least one contact for invitation");
      }

      const contactNumbers = validSuggestions.map((suggestion) => ({
        country_code: suggestion.phone_detail.country_code,
        phone_number: suggestion.phone_detail.phone_number,
      }));

      const data = {
        contact_numbers: contactNumbers,
        tribe_id: tribeId,
      };

      const response = await inviteTribes(data);
      if (response.statusCode === 200) {
        toast.success("Members invited successfully");
        return response.data;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.error("Error inviting members:", error);
      toast.error(`Error inviting members: ${error.message}`);
      throw error;
    }
  };

  const fetchContactSuggestion = async () => {
    if ("contacts" in navigator && "ContactsManager" in window) {
      try {
        const contacts = await navigator.contacts.select(["name", "tel"], {
          multiple: true,
        });

        if (contacts && contacts.length > 0) {
          const formattedContacts = contacts.map((contact) => {
            const firstTel = contact.tel[0];
            const [countryCode, phoneNumber] = firstTel.split(" ");
            return {
              first_name: contact.name[0],
              phone_detail: {
                country_code: countryCode,
                phone_number: phoneNumber,
              },
            };
          });
          setSelectedSuggestions((prev) => [...prev, ...formattedContacts]);
        } else {
          console.warn("No contacts selected");
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    } else {
      console.error("Contacts API not supported in this browser");
      setContactError("Contacts API not supported in this browser");
      dispatch(openPhoneBookModal());
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setSelectedSuggestions((prevSelected) => {
      const newSelected = prevSelected.includes(suggestion)
        ? prevSelected.filter((item) => item !== suggestion)
        : [...prevSelected, suggestion];

      return newSelected;
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
  };

  const formik = useFormik({
    initialValues: {
      tribeName: "",
    },
    validationSchema: Yup.object({
      tribeName: Yup.string().required("Tribe Name is required"),
    }),
    onSubmit: async (values) => {
      try {
        console.log(eventId)
        const tribeResponse = await cretaeTribes({
          name: values.tribeName,
          event_id: eventId,
        });
        if (tribeResponse.statusCode === 201) {
          toast.success(tribeResponse.message);
          const tribeId = tribeResponse.data._id;

          if (selectedSuggestions.length > 0) {
            await inviteMembers(tribeId);
          }
          console.log(tribeResponse, "Tribe details");

          const link = `TribeIn Invite: "${user.first_name} has invited you to join their ${tribeResponse?.data?.name} Tribe. 🎉 Let the good times roll! 🎶 Join the Tribe here: ${process.env.REACT_APP_WEBSITE_URL}?tribe_id=${tribeId}&queryType=joinTribe`;
          setTribeLink(link);
          setTribeCreated(true);
          await navigator.clipboard.writeText(link);
          toast.success("Link copied to clipboard");
          setTimeout(() => {
            navigate("/myTribes");
            dispatch(closeCreateTribe());
          }, 3000);
        } else {
          throw new Error(tribeResponse.error);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(`Error: ${error.message}`);
      }
    },
  });

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(tribeLink);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Error copying link:", error);
      toast.error(`Error copying link: ${error.message}`);
    }
  };

  useEffect(() => {
    const time = setTimeout(() => {
      fetchSuggestion();
    }, 1000);

    return () => {
      clearTimeout(time);
    };
  }, [searchInput]);

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal create_tribe_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a tribe</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className="pt-0">
            <div className="name_your_tribe">
              <h3>Name your tribe</h3>
              <Form.Group className="mb-3" controlId="tribeName">
                <Form.Control
                  type="text"
                  placeholder="Tribe Name"
                  name="tribeName"
                  onChange={formik.handleChange}
                  value={formik.values.tribeName}
                  isInvalid={!!formik.errors.tribeName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.tribeName}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="name_your_tribe">
              <h3>Invite from tribe list</h3>
              <p>
                Invite tribe members you’ve gone to events with in the past.
              </p>
              <div className="search_name_tribe">
                <Form.Group className="sreach_by_name" controlId="searchByName">
                  <Form.Control
                    type="text"
                    placeholder="Search by name"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                </Form.Group>
              </div>
              {selectedSuggestions.length > 0 && (
                <div className="search_your_tribe">
                  <div className="search_your_tribe">
                    <div className="search_tribe">
                      {selectedSuggestions?.map((suggestion, index) => (
                        <button key={index}>
                          {suggestion.first_name}{" "}
                          <img
                            src={Whitecorss}
                            alt=""
                            onClick={() => handleSuggestionSelect(suggestion)}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="sugges_tion">
                <div className="sugges_tion_head">
                  <h3>Suggestions</h3>
                </div>
                <ul className="sugges_tion_flx">
                  {suggestion.length > 0 &&
                    suggestion?.slice(0, 6)?.map(
                      (item) =>
                        item.first_name && (
                          <li key={item._id}>
                            <div
                              className="sugges_tion_crd"
                              onClick={() => handleSuggestionSelect(item)}
                            >
                              <div className="sugges_tion_list">
                                <div className="sugges_tion_img">
                                  <img
                                    src={item?.profile_pic || userprofileimage}
                                    alt="Suggestiouserone"
                                  />
                                </div>
                                <div className="sugges_tion_logo_img">
                                  <img
                                    src={Suggestionlogo}
                                    alt="Suggestionlogo"
                                  />
                                </div>
                              </div>
                              <div className="sugges_tion_name">
                                <h3>{item?.first_name?.split(" ", 1)}</h3>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                </ul>
                <div className="or_look">
                  <Link onClick={fetchContactSuggestion}>
                    Or, look for friends in your phone book
                  </Link>
                  {/* {contactError && <p>{contactError}</p>} */}
                </div>
                {tribeCreated && (
                  <div className="copy_a_link">
                    <div className="name_your_tribe">
                      <h3>Copy a link to share with your Tribe</h3>
                    </div>
                    <div className="copy_a_link_btn">
                      <Button
                        variant="outline-light"
                        onClick={copyLinkToClipboard}
                      >
                        Copy Link
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="completeyour_profile_btn profile_photo_btn m-0">
              <Button
                variant="primary"
                className="btn_blue_large"
                type="submit"
              >
                Create Tribe
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />{" "}
      {/* Toast container for displaying toast notifications */}
    </>
  );
};

export default Createtribe;
