import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { closeSuccessfullCompletionModal } from "../../redux/Reducers/modalReducer";
import { useDispatch } from "react-redux";

const Requestsuccessfullysubmitted = (props) => {
  const dispatch = useDispatch();
  const [value2, setValue2] = React.useState(75);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_icon">
              <img src={Succes} alt="Succes" />
            </div>
            <div className="reportsuccessfully_txt">
              <h2>
                Report Successfully<br></br> Submitted
              </h2>
              <p>
                Your report regarding hate speech has been received and
                forwarded. We will investigate and take appropriate action
                according to our policies. Thank you for helping maintain a safe
                environment at TribeIn
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button
              variant="primary"
              className="btn_blue_large"
              onClick={() => dispatch(closeSuccessfullCompletionModal())}
            >
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Requestsuccessfullysubmitted;
