import { z } from "zod";
import UpdateProfileWrapper from "../modals/updateProfile/UpdateProfileWrapper";
import { UserProfile } from "../types/user.types";
import phone from "phone";
import { useEffect } from "react";

interface Props {
  user: UserProfile;
}

const validUserSchema = z.object({
  gender: z.string(),
  dob: z.string(),
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.object(),
  address: z.object({
    name: z.string(),
    loc: z.object({
      coordinates: z.array(z.number()),
    }),
  }),
});

function EnsureAccountVerification(props: Props) {
  const { user } = props;




/* 
  const isValidUser = validUserSchema.safeParse(user);

  if (!isValidUser.success) return <UpdateProfileWrapper />; */

/*     retur <UpdateProfileWrapper  */

  return null;
}

export default EnsureAccountVerification;
