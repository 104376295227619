import React from "react";

const Festivals = () => {
  return (
    <div
      style={{
        height: "100vh",
        fontSize: "30px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Coming soon.
    </div>
  );
};

export default Festivals;
