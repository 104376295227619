import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import {
  closeExitTribeConfirmationModal,
  closeSuccessfullCompletionModal,
  openTribe,
} from "../../redux/Reducers/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { exitTribe } from "../../api/api";
import { useNavigate } from "react-router-dom";

const ExitTribeConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.model);
  const [isActive, setIsActive] = useState(false);

  const handleExitTribe = async () => {
    try {
      const response = await exitTribe({
        tribe_id: data.tribe_id,
        user_id: data.user_id,
      });
      if (response.statusCode === 200) {
        setIsActive(true);
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoinNewTribe = async () => {
    try {
      if (!isActive) {
        const response = await exitTribe({
          tribe_id: data.tribe_id,
          user_id: data.user_id,
        });
        if (response.statusCode === 200) {
          setIsActive(true);
          toast.error(response?.message);
        }
      }

      navigate(`/event/${data?.event}`);
      console.log(data.event, "id of the event");
      dispatch(closeExitTribeConfirmationModal());
      dispatch(
        openTribe({
          eventId: data.event,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_txt">
              <h2>
                Are you sure you want<br></br> to exit this tribe ?
              </h2>
              <p>
                You have the option to remain outside of this tribe or join a
                different one. If you choose to opt out, you can always join
                another tribe and tailor your preferences to find your people.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button
              variant="primary"
              className={!isActive ? `btn_blue_large` : "btn-secondary"}
              onClick={handleJoinNewTribe}
            >
              JOIN A NEW TRIBE
            </Button>
            <Button
              className={isActive ? `btn_blue_large` : "btn-secondary"}
              onClick={handleExitTribe}
            >
              CONFIRM EXIT
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExitTribeConfirmationModal;
