// queryReducer.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCategories: [],
  eventId: null,
  selectedDateAndLocation: [],
  searchQuery: "",
  eventLoading: false,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    updateSelectedCategories: (state, action) => {
      console.log("Selected categories in reducer:", action.payload);
      return {
        ...state,
        selectedCategories: action.payload,
      };
    },
    resetSelectedCategories: (state) => {
      return {
        ...state,
        selectedCategories: [],
      };
    },
    updateEventId: (state, action) => {
      // console.log("Event ID in reducer:", action.payload);
      return {
        ...state,
        eventId: action.payload,
      };
    },
    setDateAndLocation: (state, action) => {
      console.log(action.payload, "date and location");
      return {
        ...state,
        selectedDateAndLocation: action.payload,
      };
    },
    resetDateAndLocation: (state, action) => {
      return {
        ...state,
        selectedDateAndLocation: [],
      };
    },
    setEventLoading: (state, action) => {
      return {
        ...state,
        eventLoading: true,
      };
    },
    resetEventLoading: (state, action) => {
      return {
        ...state,
        eventLoading: false,
      };
    },
    setSearchQuery: (state, action) => {
      return {
        ...state,
        searchQuery: action.payload,
      };
    },
  },
});

export const {
  updateSelectedCategories,
  resetSelectedCategories,
  updateEventId,
  setDateAndLocation,
  resetDateAndLocation,
  setEventLoading,
  resetEventLoading,
  setSearchQuery,
} = querySlice.actions;

export default querySlice.reducer;
