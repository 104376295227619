import { useInfiniteQuery } from "@tanstack/react-query";
import { getMessages } from "../../../api/api";
import MessageResponseSchema, { MessageResponse } from "./messages.schema";

type SortOrder = 1 | -1;

interface MessagePayload {
  sender_id: string;
  search_query: string;
  sort_by: "createdAt";
  order: SortOrder;
  limit: number;
}

const payload: MessagePayload = {
  sender_id: "",
  search_query: "",
  sort_by: "createdAt",
  order: -1,
  limit: 50,
};

export const useInfiniteMessages = (selectedTribeId: string) => {
  return useInfiniteQuery<MessageResponse, Error>({
    enabled: typeof selectedTribeId !== "undefined",
    queryKey: ["messages", selectedTribeId],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await getMessages({
          ...payload,
          tribe_id: selectedTribeId,
          page: pageParam,
        });

        return MessageResponseSchema.parse(response.data);
      } catch (error) {
        throw new Error(
          error instanceof Error ? error.message : "Failed to fetch messages"
        );
      }
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.currentPage + 1 : undefined,
  });
};
