import { UseQueryResult } from "@tanstack/react-query";
import { ChevronLeft } from "lucide-react";
import { Link } from "react-router-dom";
import { Tribe, TribesPaginationResponse } from "../hooks/tribes.schema";

interface Props {
  onChatClick: (id: string) => void;
  tribes: UseQueryResult<TribesPaginationResponse>;
  selectedTribe: Tribe;
}

function ChatList(props: Props) {
  const { onChatClick, tribes, selectedTribe } = props;

  if (tribes.status === "pending") {
    return <div>Loading...</div>;
  }

  if (tribes.status === "error") {
    return <div>Error</div>;
  }

  return (
    <div className="overflow-y-auto w-full px-4 py-8 fixed lg:static max-h-[calc(100dvh-4.5rem)]">
      <div className="text-primary-200 text-sm font-medium mb-4">
        <Link className="text-primary-200 flex items-center gap-2 lg:hidden " to="/">
          <ChevronLeft /> Back to homepage
        </Link>{" "}
        <div className="text-lg font-semibold text-primary-200 tracking-tight">Your Tribes 
        🙌

        </div>
      </div>
      {tribes.data?.docs.map((tribe) => (
        <button
          key={tribe._id}
          onClick={() => onChatClick(tribe._id)}
          className={`flex flex-col gap-2 w-full py-3 border-b border-primary-900  transition-all duration ${
            selectedTribe?._id === tribe._id
              ? "bg-gradient-to-tr  backdrop-blur-lg   border-primary-500"
              : ""
          }`}
        >
          <div className="flex items-center gap-2">
            <div>
              <img
                alt={tribe.event_name}
                className="h-14 w-14 rounded-full object-cover "
                src={tribe.tribe_logo}
              />
            </div>
            <div className="flex flex-col text-left">
              <div
                className={`font-medium max-w-[15rem] overflow-hidden truncate ${
                  selectedTribe?._id === tribe._id
                    ? "text-utility-pink"
                    : "text-primary-100"
                }`}
              >
                <div className="text-sm">{tribe.event_name}</div>
              </div>
              <div className="text-primary-500 font-medium text-xs">
                {tribe.joinees.length} Member{tribe.joinees.length > 1 && "s"}
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default ChatList;
