import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UpdatePreferencesImg from "../../assets/img/UpdatePreferencesImg.jpg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Badge from "react-bootstrap/Badge";
import { addPreferences, addUserDetails, genereListing } from "../../api/api";
import {
  closePreferencesModal,
  openCaptchaModal,
  openIntegrationModal,
  openSocialConnectionModal,
} from "../../redux/Reducers/modalReducer";
import { AGE_PREFERENCES, GENDER_PREFERENCES } from "../../helpers/constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";
import { calculateAge, sortByName } from "../../helpers/utils";

const Preferences = (props) => {
  const { user } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedAge, setSelectedAge] = useState(null);
  const [selectedGender, setSelectedGender] = useState();
  const [genresError, setGenresError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [genere, setGenere] = useState([]);
  const [multiGenere, setMultiGenere] = useState();

  const fetchGeneres = async () => {
    try {
      const response = await genereListing();
      if (response?.data?.statusCode === 200) {
        let genere = response?.data?.data;
        sortByName(genere);
        let multiGenereId = genere.find((obj) => obj.name === "Multi-Genre");
        setGenere(genere);
        setMultiGenere(multiGenereId._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchGeneres();
  }, []);

  const handleAgeChange = (age) => {
    setSelectedAge(age.value);
  };

  const handleGenderChange = (gender) => {
    setSelectedGender(gender.value);
  };
  const handleGenresChange = (genre) => {
    if (genre === multiGenere) {
      const allSelected = selectedGenres.includes(genre);
      if (allSelected) {
        setSelectedGenres([]);
      } else {
        setSelectedGenres(genere.map((g) => g._id));
      }
    } else {
      const isSelected = selectedGenres.includes(genre);
      if (isSelected) {
        setSelectedGenres(selectedGenres.filter((g) => g !== genre));
      } else {
        setSelectedGenres([...selectedGenres, genre]);
      }
    }
  };

  const handleSubmit = async () => {
    let userAge = calculateAge(user?.dob);
    let preferencesData;
    if (userAge < 18) {
      setGenresError(
        selectedGenres.length === 0 ? "Music preferences are required" : ""
      );
      if (!selectedGenres.length > 0) {
        return;
      }
      preferencesData = {
        music_preferenece: selectedGenres,
      };
    } else {
      setAgeError(!selectedAge ? "Age preference is required" : "");
      setGenderError(!selectedGender ? "Gender preference is required" : "");
      setGenresError(
        selectedGenres.length === 0 ? "Music preferences are required" : ""
      );
      if (!selectedAge || !selectedGender || !selectedGenres.length > 0) {
        return;
      }
      preferencesData = {
        music_preferenece: selectedGenres,
        age_preference: selectedAge,
        gender_preference: selectedGender,
      };
    }

    const response = await addPreferences(preferencesData);
    if (response.statusCode === 201) {
      const response = await addUserDetails({ is_preference_added: true });
      if (
        response.data.is_social_accounts_added === true ||
        response.data.is_social_accounts_skipped === true
      ) {
        if (response.data.is_music_payment_account_added === true) {
          if (response.data.is_captacha_verified === true) {
            toast.success(response.message);
            dispatch(setUser(response.data.user));
            dispatch(closePreferencesModal());
            dispatch(setUserLogin());
          } else {
            dispatch(closePreferencesModal());
            dispatch(openCaptchaModal());
          }
        } else {
          dispatch(closePreferencesModal());
          dispatch(openIntegrationModal());
        }
      } else {
        toast.success(response.message);
        dispatch(closePreferencesModal());
        dispatch(openSocialConnectionModal());
      }
    } else {
      toast.error(response.error);
    }
  };
  const back = () => {
    dispatch(closePreferencesModal());
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile completeyour_profile_photo"
        centered
      >
        <div className="flex_login_container">
          <div className="login_video">
            <div class="login_video_img">
              <img src={UpdatePreferencesImg} alt="UpdatePreferencesImg" />
            </div>
            {/* <video autoPlay loop muted className="w-full h-full object-cover">
              <source src={EventVideo} type="video/mp4" />
            </video> */}
          </div>
          <div className="welcome_logo">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="completeyour_profile">
                  <h2 onClick={back} className="back_arrow_signin">
                    <div className="back_profile_icon d-none">
                      <img
                        src={Backicon}
                        alt="logo-icon"
                        className="Backicon"
                      />
                    </div>
                    Complete your profile
                  </h2>
                </div>
              </Modal.Title>
            </Modal.Header>
            <div className="personal_info">
              <h2>
                <span>3</span> Preferences{" "}
              </h2>
              <ProgressBar now={80} />;
            </div>
            <Form>
              {user && user?.dob && calculateAge(user?.dob) >= 18 && (
                <>
                  <div className="form_row">
                    <div
                      className={
                        selectedGender
                          ? "form_row_icon icon_active"
                          : "form_row_icon"
                      }
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1_567)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.68681 2.66691C2.14698 3.20673 1.83325 4.05709 1.83325 5.31335V8.64669C1.83325 10.2702 2.16236 11.0828 2.6625 11.5204C3.17916 11.9725 4.00876 12.1467 5.33325 12.1467H5.66659C5.85513 12.1467 6.03856 12.2055 6.18283 12.277C6.32528 12.3477 6.4864 12.4588 6.6017 12.6157L7.59992 13.9467C7.73936 14.1326 7.88906 14.1867 7.99992 14.1867C8.11078 14.1867 8.26048 14.1326 8.39992 13.9467L9.39992 12.6134L9.40184 12.6108C9.62162 12.3216 9.96797 12.1467 10.3333 12.1467H10.6666C11.9228 12.1467 12.7732 11.833 13.313 11.2931C13.8529 10.7533 14.1666 9.90295 14.1666 8.64669V5.31335C14.1666 4.05709 13.8529 3.20673 13.313 2.66691C12.7732 2.12708 11.9228 1.81335 10.6666 1.81335H5.33325C4.07699 1.81335 3.22663 2.12708 2.68681 2.66691ZM1.9797 1.9598C2.77321 1.16629 3.92285 0.813354 5.33325 0.813354H10.6666C12.077 0.813354 13.2266 1.16629 14.0201 1.9598C14.8136 2.75331 15.1666 3.90295 15.1666 5.31335V8.64669C15.1666 10.0571 14.8136 11.2067 14.0201 12.0002C13.2266 12.7937 12.077 13.1467 10.6666 13.1467H10.3333C10.2855 13.1467 10.2322 13.1714 10.1986 13.2151L10.198 13.2159L9.19992 14.5467C8.89936 14.9474 8.46906 15.1867 7.99992 15.1867C7.53078 15.1867 7.10048 14.9474 6.79992 14.5467L5.7991 13.2123C5.79761 13.2109 5.79461 13.2081 5.78972 13.2043C5.77766 13.1949 5.76017 13.1837 5.73868 13.173C5.71724 13.1624 5.6968 13.1548 5.6804 13.1504C5.67075 13.1478 5.66322 13.1467 5.66322 13.1467H5.33325C3.99108 13.1467 2.82068 12.9876 2.004 12.273C1.17081 11.5439 0.833252 10.3565 0.833252 8.64669V5.31335C0.833252 3.90295 1.18619 2.75331 1.9797 1.9598Z"
                            fill="#E6E9FF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.1643 7.31335C10.1643 7.03721 10.3882 6.81335 10.6643 6.81335H10.6703C10.9464 6.81335 11.1703 7.03721 11.1703 7.31335C11.1703 7.5895 10.9464 7.81335 10.6703 7.81335H10.6643C10.3882 7.81335 10.1643 7.5895 10.1643 7.31335Z"
                            fill="#E6E9FF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.49683 7.31335C7.49683 7.03721 7.72068 6.81335 7.99683 6.81335H8.00281C8.27896 6.81335 8.50281 7.03721 8.50281 7.31335C8.50281 7.5895 8.27896 7.81335 8.00281 7.81335H7.99683C7.72068 7.81335 7.49683 7.5895 7.49683 7.31335Z"
                            fill="#E6E9FF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.82959 7.31335C4.82959 7.03721 5.05345 6.81335 5.32959 6.81335H5.33558C5.61172 6.81335 5.83558 7.03721 5.83558 7.31335C5.83558 7.5895 5.61172 7.81335 5.33558 7.81335H5.32959C5.05345 7.81335 4.82959 7.5895 4.82959 7.31335Z"
                            fill="#E6E9FF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_567">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Gender Preference for Tribe"
                      options={GENDER_PREFERENCES}
                      onChange={handleGenderChange}
                    />
                    {!selectedGender && genderError && (
                      <small className="text-danger">{genderError}</small>
                    )}
                  </div>
                  <div className="form_row">
                    <div
                      className={
                        selectedAge
                          ? "form_row_icon icon_active"
                          : "form_row_icon"
                      }
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.33325 0.833313C5.60939 0.833313 5.83325 1.05717 5.83325 1.33331V3.33331C5.83325 3.60946 5.60939 3.83331 5.33325 3.83331C5.05711 3.83331 4.83325 3.60946 4.83325 3.33331V1.33331C4.83325 1.05717 5.05711 0.833313 5.33325 0.833313Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.6667 0.833313C10.9429 0.833313 11.1667 1.05717 11.1667 1.33331V3.33331C11.1667 3.60946 10.9429 3.83331 10.6667 3.83331C10.3906 3.83331 10.1667 3.60946 10.1667 3.33331V1.33331C10.1667 1.05717 10.3906 0.833313 10.6667 0.833313Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.83325 6.06C1.83325 5.78386 2.05711 5.56 2.33325 5.56H13.6666C13.9427 5.56 14.1666 5.78386 14.1666 6.06C14.1666 6.33614 13.9427 6.56 13.6666 6.56H2.33325C2.05711 6.56 1.83325 6.33614 1.83325 6.06Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.15806 3.59021C2.73091 4.05022 2.5 4.74329 2.5 5.66665V11.3333C2.5 12.2567 2.73091 12.9497 3.15806 13.4098C3.57899 13.8631 4.26197 14.1666 5.33333 14.1666H10.6667C11.738 14.1666 12.421 13.8631 12.8419 13.4098C13.2691 12.9497 13.5 12.2567 13.5 11.3333V5.66665C13.5 4.74329 13.2691 4.05022 12.8419 3.59021C12.421 3.1369 11.738 2.83331 10.6667 2.83331H5.33333C4.26197 2.83331 3.57899 3.1369 3.15806 3.59021ZM2.42527 2.90975C3.08768 2.19639 4.07137 1.83331 5.33333 1.83331H10.6667C11.9286 1.83331 12.9123 2.19639 13.5747 2.90975C14.2309 3.61641 14.5 4.59 14.5 5.66665V11.3333C14.5 12.41 14.2309 13.3835 13.5747 14.0902C12.9123 14.8036 11.9286 15.1666 10.6667 15.1666H5.33333C4.07137 15.1666 3.08768 14.8036 2.42527 14.0902C1.76909 13.3835 1.5 12.41 1.5 11.3333V5.66665C1.5 4.59 1.76909 3.61641 2.42527 2.90975Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.49683 9.1333C7.49683 8.85716 7.72068 8.6333 7.99683 8.6333H8.00281C8.27896 8.6333 8.50281 8.85716 8.50281 9.1333C8.50281 9.40944 8.27896 9.6333 8.00281 9.6333H7.99683C7.72068 9.6333 7.49683 9.40944 7.49683 9.1333Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.02954 9.1333C5.02954 8.85716 5.2534 8.6333 5.52954 8.6333H5.53553C5.81167 8.6333 6.03553 8.85716 6.03553 9.1333C6.03553 9.40944 5.81167 9.6333 5.53553 9.6333H5.52954C5.2534 9.6333 5.02954 9.40944 5.02954 9.1333Z"
                          fill="#E6E9FF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.02954 11.1333C5.02954 10.8572 5.2534 10.6333 5.52954 10.6333H5.53553C5.81167 10.6333 6.03553 10.8572 6.03553 11.1333C6.03553 11.4094 5.81167 11.6333 5.53553 11.6333H5.52954C5.2534 11.6333 5.02954 11.4094 5.02954 11.1333Z"
                          fill="#E6E9FF"
                        />
                      </svg>
                    </div>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={AGE_PREFERENCES}
                      onChange={handleAgeChange}
                      placeholder="Age Preference for Tribe"
                      closeOnSelect="false"
                    />
                    {!selectedAge && ageError && (
                      <small className="text-danger">{ageError}</small>
                    )}
                  </div>
                </>
              )}
              <div className="form_row">
                <div className="music_bage">
                  <h3 className="profile_label"> Music</h3>
                  <div className="select_bage_flx">
                    <Badge
                      onChange={handleGenresChange}
                      bg={
                        selectedGenres.includes(multiGenere)
                          ? "primary is_active"
                          : "primary"
                      }
                      onClick={() => handleGenresChange(multiGenere)}
                    >
                      All
                    </Badge>
                    {genere?.map((gener, key) => (
                      <>
                        <Badge
                          key={key}
                          // className={gener._id === multiGenere ? "d-none" : ""}
                          bg={
                            selectedGenres.includes(gener._id) ||
                            selectedGenres.includes(multiGenere)
                              ? "primary is_active"
                              : "primary"
                          }
                          onClick={() => handleGenresChange(gener._id)}
                          style={{ cursor: "pointer" }}
                        >
                          {gener.name}
                        </Badge>
                      </>
                    ))}
                    {/* <Badge bg="primary" onChange={handleGenresChange}>
                      Other
                    </Badge> */}
                    {selectedGenres.length < 1 && genresError && (
                      <small className="text-danger">{genresError}</small>
                    )}
                  </div>
                </div>
              </div>
              <div className="completeyour_profile_btn">
                <Button
                  variant="secondary"
                  className={
                    user &&
                    user?.dob &&
                    calculateAge(user?.dob) < 18 &&
                    selectedGenres.length > 0
                      ? "is_btn_active"
                      : user &&
                        user?.dob &&
                        calculateAge(user?.dob) >= 18 &&
                        selectedAge &&
                        selectedGender &&
                        selectedGenres.length > 0
                      ? "is_btn_active"
                      : "secondary"
                  }
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Preferences;
