import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import mixpanel from "mixpanel-browser";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../src/redux/Store/store";
import "./App.scss";
import { SocketProvider } from "./context/SocketContext";
import AppRoutes from "./Routes/AppRoutes";

const queryClient = new QueryClient();

// Near entry of your product, init Mixpanel
mixpanel.init("3e678e689a016fca13ce3a8872ba9a8c", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const App = () => {
  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={<>Loading....</>} persistor={persistor}>
            <SocketProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </SocketProvider>
          </PersistGate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
