import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Tribe } from "../../Component/Index/Events/tribe.schema";
import { Button } from "../../Component/shared/Button";
import { MAX_TRIBE_USERS } from "./TribeInfo";

const CircularImageLayout = ({
  tribe,
  onJoin,
  pending,
}: {
  tribe: Tribe;
  onJoin: (tribeId: string) => void;
  pending: boolean;
}) => {
  const [showCenter, setShowCenter] = useState(false);
  const [activePortraits, setActivePortraits] = useState<number[]>([]);
  const [logoLoaded, setLogoLoaded] = useState(false);

  const radius = 180;
  const totalSlots = 6;
  const existingUsers = tribe.tribeUsers || [];

  const allPositions = Array.from({ length: totalSlots }, (_, index) => {
    const angle = (index * 2 * Math.PI) / totalSlots;
    const user = existingUsers[index];
    return {
      user,
      x: Math.cos(angle) * radius,
      y: Math.sin(angle) * radius,
    };
  });

  useEffect(() => {
    // Faster initial delay
    setTimeout(() => setShowCenter(true), 200);

    const portraitDelay = 400; // Reduced from 600
    const staggerDelay = 100; // Reduced from 200

    allPositions.forEach((_, index) => {
      setTimeout(() => {
        setActivePortraits((prev) => [...prev, index]);
      }, portraitDelay + index * staggerDelay);
    });
  }, []);

  const isFull = tribe.tribeUsers?.length === MAX_TRIBE_USERS;

  return (
    <div>
      <div className="relative min-h-[50vh] h-full flex items-center justify-center">
        {/* Center image with snappy animation */}
        <div
          className={`absolute z-10
            ${logoLoaded ? "blur-0" : "blur-sm"}
            `}
          style={{
            transform: `scale(${showCenter ? 1 : 0}) rotate(${
              showCenter ? 0 : -180
            }deg)`,
            opacity: showCenter ? 1 : 0,
            transition: "all 500ms cubic-bezier(0.16, 1, 0.3, 1)",
          }}
        >
          <img
            src={tribe.tribe_logo}
            alt={`${tribe.name || "Tribe"} logo`}
            className="w-64 h-64 rounded-full object-cover"
            onLoad={() => setLogoLoaded(true)}
          />
        </div>

        {/* Animated portraits with snappy transitions */}
        {allPositions.map((position, index) => {
          const scale = activePortraits.includes(index) ? 1 : 0;
          return (
            <div
              key={position.user?.id || `placeholder-${index}`}
              className="absolute w-20 h-20"
              style={{
                transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                opacity: scale,
                transition: "all 400ms cubic-bezier(0.16, 1, 0.3, 1)",
              }}
            >
              {position.user ? (
                <div className="group w-20 h-20">
                  <img
                    src={
                      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                    }
                    className="w-18 h-18 rounded-full object-cover transition-transform group-hover:scale-110"
                    alt={`${position.user.user?.first_name || "User"} profile`}
                  />
                  <div className="text-white text-center text-xs mt-2 font-medium">
                    {position.user.user?.first_name || "User"}
                  </div>
                </div>
              ) : (
                <div className="group w-full h-full">
                  <div className="w-20 h-20 rounded-full bg-primary-800 flex items-center justify-center transition-transform">
                    <svg
                      width="57"
                      height="50"
                      viewBox="0 0 57 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3_1818)">
                        <path
                          d="M40.1443 9.2399C40.1443 11.1576 38.5894 12.7145 36.6697 12.7145C34.7499 12.7145 33.195 11.1597 33.195 9.2399H27.6141V40.5481L45.7232 22.3499V9.2399H40.1423H40.1443Z"
                          fill="white"
                        />
                        <path
                          d="M11.9539 22.4389V28.0198C13.8716 28.0198 15.4285 29.5746 15.4285 31.4944C15.4285 33.4142 13.8736 34.9691 11.9539 34.9691V40.55H27.6161L24.5286 22.4409H11.9539V22.4389Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3_1818">
                          <rect
                            width="33.7713"
                            height="31.3082"
                            fill="white"
                            transform="translate(11.9539 9.23981)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="text-neutral-100 text-center">
        <div className="text-xl">{tribe.event_name}</div>
        <div className="text-sm mt-1 text-neutral-400 text-center">
          {tribe.tribeUsers?.length ?? 0} / {MAX_TRIBE_USERS} Member{tribe.tribeUsers?.length && tribe.tribeUsers.length > 0 ? "s" : ""}
        </div>
      </div>
      <Button
        disabled={pending || isFull}
        onClick={() => onJoin(tribe._id)}
        className="w-full font-semibold mt-8 uppercase text-md "
        size={"lg"}
      >
        {pending ? <div className="flex items-center gap-2">Joining Tribe <Spinner size="sm" /> </div> : "Join Tribe"}
      </Button>
    </div>
  );
};

export { CircularImageLayout };

