import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useDispatch } from "react-redux";
import { closeTribeExitedMaximumTimes } from "../../redux/Reducers/modalReducer";

const TribeExitedMaximumTimes = (props) => {
  const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(closeTribeExitedMaximumTimes());
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_txt">
              <h2>You've reached the limit for joining tribes</h2>
              <p>
                We've noticed that you've joined and exited tribes multiple
                times for this event. To ensure the best experience for all our
                users, we encourage consistent participation within a tribe.To
                prevent disruption and ensure a cohesive environment, we've
                limited your ability to join new tribes for this event. If you
                have any questions or concerns, please don't hesitate to reach
                out to our support team at hello@tribe-in.com. Thank you for
                your understanding.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0 d-flex">
            <Button
              variant="primary"
              className="btn_blue_large is_btn_active"
              onClick={handleContinue}
            >
              Understood
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TribeExitedMaximumTimes;
