import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOtpModal,
  closeUpdatePhoneVerifyOtp,
} from "../../redux/Reducers/modalReducer";
import { addUserDetails, sendOtp, verifyOtp } from "../../api/api";
import { toast } from "react-toastify";
import { setUser, setOtpCredToResend } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";

const UpdatePhoneOtpVerify = (props) => {
  let { otpCredToResend } = useSelector((state) => state?.user);
  const { data } = useSelector((state) => state.model);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpError, setOtpError] = useState("");

  const handleResendOtp = async () => {
    let dataToSend;

    if (data?.user_id) {
      dataToSend = {
        device_type: "phone",
        user_id: data?.user_id,
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      };
    } else {
      dataToSend = {
        device_type: "phone",
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      };
    }

    const response = await sendOtp(dataToSend);
    dispatch(
      setOtpCredToResend({
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      })
    );
    if (response?.statusCode === 200) {
      localStorage.setItem("token", response.data.token);
      toast.success(response.message);
    } else {
      toast.error(response.error);
    }
  };
  const back = () => {
    dispatch(closeOtpModal());
  };
  const handleSubmit = async () => {
    try {
      if (!otp) {
        setOtpError("OTP is required");
        return;
      }
      setIsLoading(true);
      let payload = {
        token: localStorage.getItem("token"),
        otp: otp,
        phone_number: otpCredToResend.phone_number,
        country_code: otpCredToResend.country_code,
      };
      const response = await verifyOtp(payload);
      if (response.statusCode === 200) {
        const updateResponse = await addUserDetails({
          phone_detail: {
            phone_number: otpCredToResend.phone_number,
            country_code: otpCredToResend.country_code,
          },
        });
        if (updateResponse.statusCode === 200) {
          dispatch(closeUpdatePhoneVerifyOtp());
          dispatch(setUser(response?.data?.user));
          toast.success("Phone number updated sucessfully");
        }
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal signin_inner_modal otp_inner_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 onClick={back} className="back_arrow_signin">
              <div className="back_profile_icon">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Enter Your code </h4>
          <p>
            (+{otpCredToResend.country_code}) {otpCredToResend.phone_number}{" "}
            <Link onClick={handleResendOtp}>Resend</Link>
          </p>
          <div className="otp_code">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span> </span>}
              inputStyle={{}}
              inputType="tel"
              renderInput={(props) => (
                <input {...props} className="input-container-otp-modal" />
              )}
            />
          </div>
          {otpError && otp.length < 6 && (
            <small className="text-danger">{otpError}</small>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            className={otp.length === 6 ? "is_btn_active" : ""}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePhoneOtpVerify;
