import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmationModal,
  openFilterModal,
  openSignup,
} from "../../redux/Reducers/modalReducer";
import Form from "react-bootstrap/Form";
import {
  FillterIcon,
  GreyCross,
  Headerserach,
  HydrationReminder,
  Logout,
  NewMessage,
  Notification,
  SearchCross,
  ShowTimeIcon,
  TribeUpdate,
} from "../utils/icon";
import { ToggleIcon } from "../utils/icon";
import Button from "react-bootstrap/Button";
import LogoIcon from "../../assets/img/LogoIcon.svg";
import Mobileheaderlogo from "../../assets/img/Mobileheaderlogo.svg";
import Categories from "../../modals/categories/categories.js";
import Dropdown from "react-bootstrap/Dropdown";
import { setSearchQuery } from "../../redux/Reducers/queryReducer";
import { CONFIRMATION_TYPES } from "../../helpers/constant";
import useWindowWidth from "../../hooks/useWindowWidth.js";
import userprofileimage from "../../assets/img/profile-pic.svg";
import { Link } from "react-router-dom";
import { openCategories } from "../../redux/Reducers/modalReducer.js";
import { openLogin } from "../../redux/Reducers/modalReducer";
import MainBanner from "../../assets/img/Main Banner.svg";
const Header = ({ handleSidebar }) => {
  const { user, isUserLogedIn } = useSelector((state) => state?.user);
  const { searchQuery } = useSelector((state) => state?.query);
  const [profilePic, setProfilePic] = useState();
  const [searchValue, setSearchValue] = useState(searchQuery || "");
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const location = useLocation();

  useEffect(() => {
    if (user && user.profile_pic === null) {
      setProfilePic(userprofileimage);
    } else {
      setProfilePic(user?.profile_pic);
    }
  }, [user]);

  const urlLocation = useLocation();

  const handleClick = (event) => {
    // Check if the current location is the homepage
    if (urlLocation.pathname === "/") {
      // If on the homepage, reload the page
      window.location.reload();
    } else {
      // Prevent default action if not on the homepage
      event.preventDefault();
      navigate("/");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(openFilterModal());
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFestival = (e) => {
    e.preventDefault();
    dispatch(setSearchQuery(searchValue));
    navigate(`/events`, {
      state: { search_query: searchValue || "" },
    });
  };

  useEffect(() => {
    const debounceTime = setTimeout(() => {
      dispatch(setSearchQuery(searchValue));
    }, 4000);
    return () => {
      clearTimeout(debounceTime);
    };
  }, [searchValue]);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const closedDropdown = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    const data = {
      mode: CONFIRMATION_TYPES.LOGOUT,
      message: "Are you sure you want to logout?",
    };
    dispatch(openConfirmationModal(data));
  };
  const handleShowSearchBar = () => {
    setShowSearch(!showSearch);
  };
  return (
    <>
      <header className="main_header">
        <div className="mobile_header_top_m">
          <div className="notification_count d-none">
            <div className="notification_bar">
              <>
                <Dropdown show={showDropdown} onToggle={toggleDropdown}>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="p-0"
                  >
                    <div className="notification_count d-none">
                      <span className="notification_badge"></span>
                      <Notification />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    ref={dropdownRef}
                    className={showDropdown ? "show" : ""}
                  >
                    <div className="notification_crd">
                      <div className="notification_head">
                        <div className="notification_head_txt">
                          <h3>Notifications</h3>
                        </div>
                        <div className="cross_btn" onClick={closedDropdown}>
                          <GreyCross />
                        </div>
                      </div>
                      <div className="today_crd_bx">
                        <div className="today_crd">
                          <div className="today_crd_head">
                            <h4>Today</h4>
                          </div>
                          <div className="show_time_card show_time">
                            <div className="show_time_flx">
                              <div className="show_time_head_flx">
                                <div className="show_time_head">
                                  <h5>
                                    <span>
                                      <ShowTimeIcon />
                                    </span>{" "}
                                    Showtime
                                  </h5>
                                </div>
                                <div className="time_txt">
                                  <p>2 min ago</p>
                                </div>
                              </div>
                              <div className="show_time_desc">
                                <p>
                                  Get ready! Your favorite artist is about to
                                  hit the stage at Coachella in just 15 minutes.
                                  Don't miss out! Stay updated with TribeIn's
                                  show time reminder.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="show_time_card hydration_reminder">
                            <div className="show_time_flx">
                              <div className="show_time_head_flx">
                                <div className="show_time_head">
                                  <h5>
                                    <span>
                                      <HydrationReminder />
                                    </span>{" "}
                                    Hydration Reminder
                                  </h5>
                                </div>
                                <div className="time_txt">
                                  <p>1 hour ago</p>
                                </div>
                              </div>
                              <div className="show_time_desc">
                                <p>
                                  Remember to stay hydrated at Coachella 2024!
                                  Drink water regularly to keep energized and
                                  enjoy the festival to the fullest.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="today_crd yesterday_crd">
                          <div className="today_crd_head">
                            <h4>Yesterday</h4>
                          </div>
                          <div className="show_time_card ">
                            <div className="show_time_flx">
                              <div className="show_time_head_flx">
                                <div className="show_time_head">
                                  <h5>
                                    <span>
                                      <TribeUpdate />
                                    </span>{" "}
                                    Tribe Update
                                  </h5>
                                </div>
                                <div className="time_txt">
                                  <p>1 day ago</p>
                                </div>
                              </div>
                              <div className="show_time_desc">
                                <p>
                                  Your tribe has grown! Check out the latest
                                  members and start connecting with fellow
                                  attendees of Coachella 2024.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="show_time_card">
                            <div className="show_time_flx">
                              <div className="show_time_head_flx">
                                <div className="show_time_head">
                                  <h5>
                                    <span>
                                      <NewMessage />
                                    </span>{" "}
                                    New Message
                                  </h5>
                                </div>
                                <div className="time_txt">
                                  <p>2 days ago</p>
                                </div>
                              </div>
                              <div className="show_time_desc">
                                <p>
                                  You've received a new message from a tribe
                                  member. Open TribeIn to see what they have to
                                  say about Coachella 2024.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="notification_footer">
                      <div className="notification_footer_txt">
                        <p>2 New notifications</p>
                      </div>
                      <div className="notification_footer_btn">
                        <Button variant="primary" className="btn_blue">
                          mark all as read
                        </Button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            </div>
          </div>
          <div className="mobile_header_logo" onClick={() => navigate("/")}>
            <img
              src={Mobileheaderlogo}
              alt="logo-icon"
              className="Mobileheaderlogo"
            />
          </div>
          <div className="toggle_btn" onClick={handleSidebar}>
            <ToggleIcon />
          </div>
        </div>
        <div
          className={`main_header_flex ${
            width < 997 && location.pathname === "/" && "d-flex"
          } is_login`}
        >
          <div className="wlcm_txt">
            <div className="header_logo">
              <img src={LogoIcon} alt="logo-icon" className="logo" />
            </div>
            {isUserLogedIn ? (
              <></>
            ) : (
              <div className="hero">
                <div className="hero-title">
                  Experience Live Events{" "}
                  <span className="highlight">Together</span>
                </div>
                <div className="hero-description">
                  Join Group Chats | Find Your Tribe
                </div>
              </div>
            )}
          </div>

          <div className="form_warper">
            {isUserLogedIn ? (
              <div className="notification_bar">
                <>
                  <Dropdown show={showDropdown} onToggle={toggleDropdown}>
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="p-0"
                    >
                      <div className="notification_count d-none">
                        <span className="notification_badge"></span>
                        <Notification />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      ref={dropdownRef}
                      className={showDropdown ? "show d-none" : ""}
                    >
                      <div className="notification_crd">
                        <div className="notification_head">
                          <div className="notification_head_txt">
                            <h3>Notifications</h3>
                          </div>
                          <div className="cross_btn" onClick={closedDropdown}>
                            <GreyCross />
                          </div>
                        </div>
                        <div className="today_crd_bx">
                          <div className="today_crd">
                            <div className="today_crd_head">
                              <h4>Today</h4>
                            </div>
                            <div className="show_time_card show_time">
                              <div className="show_time_flx">
                                <div className="show_time_head_flx">
                                  <div className="show_time_head">
                                    <h5>
                                      <span>
                                        <ShowTimeIcon />
                                      </span>{" "}
                                      Showtime
                                    </h5>
                                  </div>
                                  <div className="time_txt">
                                    <p>2 min ago</p>
                                  </div>
                                </div>
                                <div className="show_time_desc">
                                  <p>
                                    Get ready! Your favorite artist is about to
                                    hit the stage at Coachella in just 15
                                    minutes. Don't miss out! Stay updated with
                                    TribeIn's show time reminder.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="show_time_card hydration_reminder">
                              <div className="show_time_flx">
                                <div className="show_time_head_flx">
                                  <div className="show_time_head">
                                    <h5>
                                      <span>
                                        <HydrationReminder />
                                      </span>{" "}
                                      Hydration Reminder
                                    </h5>
                                  </div>
                                  <div className="time_txt">
                                    <p>1 hour ago</p>
                                  </div>
                                </div>
                                <div className="show_time_desc">
                                  <p>
                                    Remember to stay hydrated at Coachella 2024!
                                    Drink water regularly to keep energized and
                                    enjoy the festival to the fullest.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="today_crd yesterday_crd">
                            <div className="today_crd_head">
                              <h4>Yesterday</h4>
                            </div>
                            <div className="show_time_card ">
                              <div className="show_time_flx">
                                <div className="show_time_head_flx">
                                  <div className="show_time_head">
                                    <h5>
                                      <span>
                                        <TribeUpdate />
                                      </span>{" "}
                                      Tribe Update
                                    </h5>
                                  </div>
                                  <div className="time_txt">
                                    <p>1 day ago</p>
                                  </div>
                                </div>
                                <div className="show_time_desc">
                                  <p>
                                    Your tribe has grown! Check out the latest
                                    members and start connecting with fellow
                                    attendees of Coachella 2024.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="show_time_card">
                              <div className="show_time_flx">
                                <div className="show_time_head_flx">
                                  <div className="show_time_head">
                                    <h5>
                                      <span>
                                        <NewMessage />
                                      </span>{" "}
                                      New Message
                                    </h5>
                                  </div>
                                  <div className="time_txt">
                                    <p>2 days ago</p>
                                  </div>
                                </div>
                                <div className="show_time_desc">
                                  <p>
                                    You've received a new message from a tribe
                                    member. Open TribeIn to see what they have
                                    to say about Coachella 2024.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="notification_footer">
                        <div className="notification_footer_txt">
                          <p>2 New notifications</p>
                        </div>
                        <div className="notification_footer_btn">
                          <Button variant="primary" className="btn_blue">
                            mark all as read
                          </Button>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="user-wrap">
                    <div className="user-greeting">Hey</div>
                    <div className="user-name">
                      {user && !user.first_name && "User"}
                      {user &&
                        user.first_name &&
                        user.first_name.charAt(0).toUpperCase() +
                          user.first_name.slice(1).toLowerCase()}{" "}
                    </div>
                  </div>
                  <div className="admin_img_header_dropdown">
                    <div className="admin_img_header">
                      <img
                        src={
                          profilePic?.startsWith("public")
                            ? userprofileimage
                            : profilePic
                        }
                        alt="Emilyprofile"
                      />
                    </div>
                    <div className="logout_btn">
                      <Link className="d-flex" onClick={handleLogout}>
                        <span className="logout_icon">
                          <Logout />
                        </span>
                        <span className="text_name">Logout</span>
                      </Link>
                    </div>
                  </div>
                </>
              </div>
            ) : (
              <Button
                variant="primary"
                className="btn_blue"
                // onClick={() => dispatch(openSignup())}
                onClick={() => dispatch(openLogin())}
              >
                Log In
              </Button>
            )}
            <div
              className="toggle_btn toggle_btn_desk cursor-pointer"
              onClick={handleSidebar}
            >
              <ToggleIcon />
            </div>
          </div>
        </div>
      </header>
      {/* {urlLocation.pathname === "/" && <div className="hero_banner_slider">
        <div className="hero_banner_slider_video">
          <img src={MainBanner} alt="main banner" className="w-full h-full object-contain" />
        </div>
      </div>} */}
      {/* Categories */}

      {/*      <div className="top_pill_flx">
        <div className="top_pill_link"></div>
      </div> */}

      <Categories />
    </>
  );
};

export default Header;
