import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { useDispatch } from "react-redux";
import { closeSettingConfirmation } from "../../redux/Reducers/modalReducer";

const SettingConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(closeSettingConfirmation());
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_icon">
              <img src={Succes} alt="Succes" />
            </div>
            <div className="reportsuccessfully_txt">
              <h2>
                Your preferences have been <br /> sucessfully changed
              </h2>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0 d-flex">
            <Button
              variant="primary"
              className="btn_blue_large is_btn_active"
              onClick={handleContinue}
            >
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingConfirmationModal;
