import { Button } from "@/Component/shared/Button";
import BuyTickets from "../../../../Component/TribeChat/buy-tickets/buy-tickets-dropdown";
import { Tribe } from "../../hooks/tribes.schema";
import { useEffect } from "react";
import { NAVBAR_CLASS } from "@/helpers/constants";

interface Props {
  tribe: Tribe;
  onBackClick: () => void;
  onExitChatClick: () => void;
}

function ChatHeader(props: Props) {
  const { tribe, onBackClick, onExitChatClick } = props;

  return (
    <div className="h-20 lg:h-14 top-0 w-full left-0 text-white backdrop-blur-lg bg-primary-900 lg:bg-transparent bg-opacity-50">
      <div className="flex items-center items-between gap-2 w-full  h-full px-2 lg:px-6">
        <div className="flex items-center gap-2 w-full">
          <button
            onClick={onBackClick}
            className="p-2 hover:bg-primary-800 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div className="flex-shrink-0">
            {tribe.tribe_logo && (
              <img
                src={tribe.tribe_logo}
                alt={tribe.event_name}
                className="w-10 h-10 flex-shrink-0 rounded-full object-cover"
              />
            )}
          </div>
          <div className=" flex flex-col">
            <div>
              <div className="font-semibold text-sm  max-w-[100px] md:max-w-[250px] lg:max-w-[400px] truncate overflow-hidden">
                {tribe.event_name}
              </div>
            </div>
            <div className="">
              <span className="text-sm tracking-tight text-primary-400">
                {tribe.totalJoinees || 0} members
              </span>
            </div>
          </div>
        </div>
        <button className="px-2" onClick={onExitChatClick}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 1C2.44771 1 2 1.44772 2 2V13C2 13.5523 2.44772 14 3 14H10.5C10.7761 14 11 13.7761 11 13.5C11 13.2239 10.7761 13 10.5 13H3V2L10.5 2C10.7761 2 11 1.77614 11 1.5C11 1.22386 10.7761 1 10.5 1H3ZM12.6036 4.89645C12.4083 4.70118 12.0917 4.70118 11.8964 4.89645C11.7012 5.09171 11.7012 5.40829 11.8964 5.60355L13.2929 7H6.5C6.22386 7 6 7.22386 6 7.5C6 7.77614 6.22386 8 6.5 8H13.2929L11.8964 9.39645C11.7012 9.59171 11.7012 9.90829 11.8964 10.1036C12.0917 10.2988 12.4083 10.2988 12.6036 10.1036L14.8536 7.85355C15.0488 7.65829 15.0488 7.34171 14.8536 7.14645L12.6036 4.89645Z"
              fill="currentColor"
              fill-rule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="w-[15rem]">
          <BuyTickets componentType="button" eventId={tribe.event_id} />
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
