import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL
  // baseURL: "http://172.24.0.202:3362/api/v1/"
});
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = "/";
    }
    return Promise.reject(error.response.data);
  }
);
export default axiosInstance;
