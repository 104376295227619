import { createSlice } from "@reduxjs/toolkit";

const tribeSlice = createSlice({
  name: "tribe",
  initialState: {
    tribe_id: null,
    joiningDetails: {},
  },
  reducers: {
    setTribeId(state, action) {
      state.tribe_id = action.payload;
    },
  },
});

export const { setTribeId } = tribeSlice.actions;

export default tribeSlice.reducer;
