import React, { useEffect, useState } from "react";
import { eventDetails } from "../../../api/api";
import { Button, Dropdown } from "react-bootstrap";
import "./buy-tickets-dropdown.scss";
// @ts-ignore
import GetTickets from "./get-tickets.svg";
interface Props {
  eventId: string;
  componentType: "button" | "dropdown";
}

interface Event {
  data: {
    tracking_url?: string;
  };
}

function BuyTickets(props: Props) {
  const { eventId, componentType } = props;
  const [status, setStatus] = useState<
    "loading" | "idle" | "error" | "success"
  >("idle");
  const [event, setEvent] = useState<Event>();

  async function fetchEventDetails(eventId: string) {
    setStatus("loading");
    try {
      const response = await eventDetails(eventId);
      setEvent(response.data);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  }

  useEffect(() => {
    if (eventId !== "") {
      fetchEventDetails(eventId);
    }
  }, [eventId]);

  if (status === "loading") {
    return <div className="buy-tickets-text">Loading...</div>;
  }

  if (status === "error") {
    return <div className="buy-tickets-text">Couldn't get tickets</div>;
  }

  if (!event?.data?.tracking_url) {
    return <div className="buy-tickets-text">Tickets not available</div>;
  }

  return (
    <div>
      {componentType === "button" ? (
        <a
          className="buy-get-tickets-button"
          href={event.data.tracking_url}
          target="_blank"
          rel="noreferrer"
        >
          Get Tickets
        </a>
      ) : (
        <Dropdown.Item target="_blank" href={event.data.tracking_url}>
          <img src={GetTickets} alt="CaptchUp" />
          Get Tickets
        </Dropdown.Item>
      )}
    </div>
  );
}

export default BuyTickets;
