import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import LoadingSpinner from "../../../../Component/shared/LoadingSpinner";
import { useSocket } from "../../../../context/SocketContext";
import { MessageTribe } from "../../hooks/messages.schema";
import { useInfiniteMessages } from "../../hooks/useMessages";
import { useSendMessage } from "../../hooks/useSendMessage";
import ChatHeader from "./ChatHeader";
import ChatMessageList from "./ChatMessageList";
import GifSelector from "./GifSelector";
import ChatImageUploader from "./UploadPhoto";
import { openExitTribeConfirmationModal } from "../../../../redux/Reducers/modalReducer";
import { CHAT_HEADER_CLASS, CHAT_MESSAGE_CLASS, NAVBAR_CLASS } from "../../../../helpers/constants";
import mixpanel from "mixpanel-browser";

interface Props {
  selectedTribeId: string;
  selectedTribe: MessageTribe;
  onBackClick: () => void;
}

function MessageArea(props: Props) {
  const { selectedTribeId, selectedTribe, onBackClick } = props;
  const [message, setMessage] = useState("");
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const socket = useSocket();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [maxHeight, setMaxHeight] = useState("800px");

  const { user } = useSelector((state) => state?.user);

  const { data, status, fetchNextPage } = useInfiniteMessages(selectedTribe._id);

  const { sendMessage: sendMessageMutation } = useSendMessage({
    sender_id: user?._id,
    tribe_id: selectedTribeId,
    tribeName: "",
    user: user,
    tribe: selectedTribe,
  });

  useEffect(() => {
    if (!socket) return;
    socket.emit("join chat");

    return () => {
      socket.off("socketOff");
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on("receive message", () => {
      fetchNextPage();
    });

    return () => {
      socket.off("receive message");
    };
  }, [socket, fetchNextPage]);

  const calculateMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const headerHeight = document.getElementById(NAVBAR_CLASS)?.offsetHeight || 0;
    const footerHeight = document.getElementById(CHAT_MESSAGE_CLASS)?.offsetHeight || 0;
    const inputAreaHeight = document.getElementById(CHAT_HEADER_CLASS)?.offsetHeight || 80;

    const availableHeight = viewportHeight - (headerHeight + footerHeight + inputAreaHeight);
    const minHeight = 400;
    const finalHeight = Math.max(availableHeight, minHeight);

    return `${finalHeight}px`;
  };

  useEffect(() => {
    setMaxHeight(calculateMaxHeight());

    const handleResize = () => {
      setMaxHeight(calculateMaxHeight());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [status]);

  const sendMessage = async () => {
    if (!message) return;
    mixpanel.track("Message sent", {
      
      "Event Name": selectedTribe.event_name,
      "Event ID": selectedTribe._id,
    });
    sendMessageMutation({ newMessage: message });
    setMessage("");
  };

  function handleScrollTop() {
    fetchNextPage();
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const isMobileDevice = () => window.innerWidth < 768;
  const showMediaButtons = !isTextareaFocused || !isMobileDevice();

  if (!selectedTribeId) {
    return (
      <div className="h-full flex items-center justify-center text-primary-300">
        Select a chat to start messaging
      </div>
    );
  }

  if (status === "error") {
    return <div>There was an error retrieving the chat messages</div>;
  }

  return (
    <div className="h-full">
      <div id={CHAT_HEADER_CLASS} className="flex-shrink-0">
        <ChatHeader
          onBackClick={onBackClick}
          tribe={selectedTribe}
          onExitChatClick={() => {
            dispatch(
              openExitTribeConfirmationModal({
                tribe_id: selectedTribe._id,
                user_id: user._id,
                event: selectedTribe,
              })
            );
          }}
        />
      </div>

      {status === "pending" ? (
        <div style={{maxHeight: maxHeight}} className="h-full w-full flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ChatMessageList
          numberOfPages={data.pages.length}
          onScrollTop={handleScrollTop}
          user={user}
          chatMessages={data.pages}
          ref={messagesEndRef}
          maxHeight={maxHeight}
        />
      )}

      <div id={CHAT_MESSAGE_CLASS} className="fixed flex-shrink-0 lg:static bottom-0 left-0 w-full p-4 rounded-tl-2xl bg-primary-900 backdrop-blur bg-opacity-30">
        <div className="flex items-center gap-2">
          <div className={`transition-all duration-300 ease-in-out ${showMediaButtons ? 'w-auto opacity-100' : 'w-0 opacity-0 overflow-hidden'}`}>
            <div className="flex gap-2">
              <GifSelector
                onGifSelect={(gif) => {
                  sendMessageMutation({
                    newMessage: gif?.images?.original?.url,
                    type: "gif",
                  });
                  setTimeout(() => {
                    messagesEndRef.current?.scrollIntoView();
                  }, 0);
                }}
              />
              <ChatImageUploader />
            </div>
          </div>
          <TextareaAutosize
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsTextareaFocused(true)}
            onBlur={() => setIsTextareaFocused(false)}
            className="flex-1 rounded-2xl w-full resize-none text-primary-100 border-gray-800 bg-primary-900 px-4 py-2 lg:text-sm focus:border-primary-500 focus:outline-none transition-all duration-300 ease-in-out"
            placeholder="Type a message..."
            minRows={1}
            maxRows={4}
          />
          <button
            onClick={sendMessage}
            className="flex items-center justify-center w-9 h-9 rounded-full bg-utility-blue transition-colors"
            aria-label="Send message"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-white"
            >
              <path
                d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessageArea;