import * as Yup from "yup";
import { calculateAge } from "./utils";

export const completeProfileValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "First name should only contain letters")
    .required("First name is required"),
  last_name: Yup.string().matches(
    /^[A-Za-z ]+$/,
    "Last name should only contain letters"
  ),
  address: Yup.object().shape({
    name: Yup.string().required("Valid address is required"),
    loc: Yup.object().shape({
      coordinates: Yup.array()
        .of(Yup.number().required("Coordinate is required"))
        .length(2, "Coordinates must be an array of two numbers")
        .required("Coordinates are required"),
    }),
  }),
  email: Yup.string().email("Invalid email").required("Email is required"),
  dob: Yup.date()
    .nullable()
    .required("Date of birth is required")
    .test(
      "is-18-or-older",
      "You must be at least 18 years old",
      function (value) {
        if (!value) return false;
        let age = calculateAge(value);
        return age >= 18;
      }
    ),
  gender: Yup.string()
    .matches(/^[A-Za-z]+$/, "Gender should only contain letters")
    .required("Gender is required"),
});

export const phoneNumberValidationSchema = Yup.object().shape({
  country_code: Yup.string().required("Country code is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
});

export const pollValidationSchema = Yup.object().shape({
  poll_question: Yup.string().required("Poll question is required"),
  // poll_options: Yup.array().of(Yup.string()).min(2, 'There must be at least 2 poll options').required('At least one poll option is required'),
  is_multiple_poll_answers_allowed: Yup.boolean().required(
    "Please specify if multiple answers are allowed or not"
  ),
});
