import React from "react";
import "./videocard.scss";
import { CalenderIcon, LocationIcon, UsdCricle } from "../utils/icon";
import { formatDate, truncateString } from "../../helpers/utils";
import { Link } from "react-router-dom";
import LikeLogo from "../../assets/img/like.svg";

const Videocard = ({ eventData, eventType, genereRequired }) => {
  const identifier = eventData?._id;
  const eventLink = `/event/${identifier}`;
  const completeAddress = `${eventData?.event_venue?.name} • ${" "}${eventData?.event_venue?.zip_code
    },${" "}${eventData?.event_venue?.country}`;

  return (
    <>
      {/* Wrap the content in a Link component */}
      <Link to={eventLink} className="event-link">
        <div className="buy_area_crd">
          <div className="buy_area_crd_video_bx">
            <div className="buy_area_crd_video">
              <img
                src={eventData?.banner_image}
                className="w-full h-full object-cover event-banner"
                alt="Event Banner"
              />
            </div>
            <div className="buy_area_crd_video_txt">
              {eventType ? (
                eventType === "ATTENDED" ? (
                  <div
                    className=" gener"
                    style={{
                      backgroundColor: "#00AEAE",
                      padding: "2px 2px 2px 4px ",
                      borderRadius: "4px",
                    }}
                  >
                    <img src={LikeLogo} alt="like logo" />
                    <p style={{ backgroundColor: "#00AEAE" }}>ATTENDED</p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                genereRequired !== "false" && (
                  <div className="gener">
                    <p className="text-uppercase">
                      {eventData?.genere?.length > 2
                        ? "MULTI-GENRE"
                        : eventData?.genere?.length === 2 &&
                          eventData?.genere[0]?.name === "Jazz" &&
                          eventData?.genere[1]?.name === "Vintage"
                          ? "Vintage-Jazz"
                          : eventData?.genere?.length === 2 &&
                            eventData?.genere[0]?.name === "Rock" &&
                            eventData?.genere[1]?.name === "Indie"
                            ? "Indie-Rock"
                            : eventData?.genere?.length === 2 &&
                              eventData?.genere[0]?.name === "Metal" &&
                              eventData?.genere[1]?.name === "Rock"
                              ? "Rock-Metal"
                              : eventData?.genere?.length === 2 &&
                                eventData?.genere[0]?.name === "Metal" &&
                                eventData?.genere[1]?.name === "Bass"
                                ? "Bass-Metal"
                                : eventData?.genere?.map((genere, index) => (
                                  <React.Fragment key={genere._id}>
                                    {genere.name}
                                    {index !== eventData?.genere?.length - 1 && " - "}
                                  </React.Fragment>
                                ))}
                    </p>
                  </div>
                )
              )}

              <div className="gener_txt">{<p>{eventData?.event_name}</p>}</div>
            </div>
          </div>
          <div className="buy_area_content">
            <ul>
              <li>
                <div className="buy_area_content_bx">
                  <div className="buy_area_content_icon">
                    <CalenderIcon />
                  </div>
                  <div className="buy_area_content_txt">
                    <p>
                      {eventData?.event_start_date === eventData?.event_end_date
                        ? eventData?.event_start_date &&
                        formatDate(eventData?.event_start_date, false)
                        : `${formatDate(
                          eventData?.event_start_date,
                          false
                        )} - ${formatDate(eventData?.event_end_date, false)}`}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="buy_area_content_bx">
                  <div className="buy_area_content_icon">
                    <LocationIcon />
                  </div>
                  <div className="buy_area_content_txt">
                    <p>{truncateString(completeAddress)}</p>
                  </div>
                </div>
              </li>

              <li className={eventData?.tickets?.[0]?.price ? "" : "d-none"}>
                <div className="buy_area_content_bx">
                  <div className="buy_area_content_icon">
                    <UsdCricle />
                  </div>
                  <div className="buy_area_content_txt">
                    <p>From USD ${eventData?.tickets?.[0]?.price} </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Videocard;
