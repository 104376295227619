import { User } from "@/Component/shared/VerifyUserInfo";
import { forwardRef, useEffect, useCallback } from "react";
import { MessageSchema } from "../../hooks/messages.schema";
import ChatMessage from "./ChatMessage";

interface Props {
  chatMessages: MessageSchema[];
  user: User;
  onScrollTop: () => void;
  numberOfPages: number;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  maxHeight: string;
}

const DynamicChatContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    chatMessages,
    user,
    onScrollTop,
    maxHeight,
  } = props;

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (ref?.current) {
        const container = ref.current.parentElement;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }
    }, 100);
  }, []);


  // Scroll to bottom when new messages arrive
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, scrollToBottom]);

  // Handle scroll to top
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      onScrollTop();
    }
  };

  const messages = chatMessages.flatMap((page) => page.docs);

  return (
    <div
      className="h-full overflow-y-auto"
      style={{ maxHeight }}
      onScroll={handleScroll}
    >
      {/* Message list */}
      {messages.map((doc) => {
        const isCurrentUser = doc.sender._id === user?._id;
        return (
          <ChatMessage
            key={doc._id}
            isCurrentUser={isCurrentUser}
            message={doc}
          />
        );
      })}
      <div       ref={ref}
      >

      </div>
      {/* Messages end div for scrolling */}
    </div>
  );
});

// Add display name for better debugging
DynamicChatContainer.displayName = 'DynamicChatContainer';

export default DynamicChatContainer;