import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import GradientProfile from "../../../../Component/shared/GradientProfile";

// Initialize dayjs plugins
dayjs.extend(relativeTime);

export interface MessageDoc {
  sender: {
    first_name: string;
    profile_pic?: string;
  };
  message: string;
  createdAt: string;
  type: "text" | "media" | "gif";
}

type ChatMessageProps = {
  message: MessageDoc;
  isCurrentUser: boolean;
};

const formatMessageTime = (timestamp: string) => {
  const messageDate = dayjs(timestamp);
  const now = dayjs();

  // If message is from today, show time
  if (messageDate.isSame(now, "day")) {
    return messageDate.format("h:mm A");
  }

  // If message is from this week, show day and time
  if (messageDate.isAfter(now.subtract(7, "day"))) {
    return messageDate.format("ddd h:mm A");
  }

  // If message is from this year, show month and day
  if (messageDate.isSame(now, "year")) {
    return messageDate.format("MMM D, h:mm A");
  }

  // Otherwise show full date
  return messageDate.format("MMM D, YYYY h:mm A");
};

export const ChatMessage = ({ message, isCurrentUser }: ChatMessageProps) => {
  const formattedTime = formatMessageTime(message.createdAt);

  return (
    <div className="flex flex-col p-4 flex-shrink-0 animate-slideIn w-full overflow-hidden">
      <div
        className={`flex items-start gap-3 max-w-full ${
          isCurrentUser ? "flex-row-reverse" : ""
        }`}
      >
        {!isCurrentUser && (
          <>
            {message.sender.profile_pic ? (
              <img
                src={message.sender.profile_pic}
                alt={`${message.sender.first_name}`}
                className="w-10 h-10 rounded-full object-cover animate-fadeIn flex-shrink-0"
              />
            ) : (
              <div className="animate-fadeIn flex-shrink-0">
                <GradientProfile text={message.sender.first_name.slice(0, 1)} />
              </div>
            )}
          </>
        )}
        <div
          className={`flex flex-col ${
            isCurrentUser ? "items-end" : ""
          } min-w-0 max-w-full`}
        >
          {!isCurrentUser && (
            <div className="flex items-center gap-2 w-full overflow-hidden">
              <span className="font-medium text-primary-100 animate-fadeIn truncate">
                {message.sender.first_name}
              </span>
              <span className="text-sm text-gray-500 animate-fadeIn flex-shrink-0">
                {formattedTime}
              </span>
            </div>
          )}
          {isCurrentUser && (
            <span className="text-sm text-gray-500 animate-fadeIn mb-1 flex-shrink-0">
              {formattedTime}
            </span>
          )}
          {message.type === "media" || message.type === "gif" ? (
            <div className="max-w-full overflow-hidden rounded-lg">
              <img
                src={message.message}
                alt="Media"
                className="w-full h-40 object-cover animate-scaleIn"
              />
            </div>
          ) : (
            <div
              className={`p-3 text-xs max-w-lg md:text-sm rounded-2xl break-words animate-messageIn ${
                isCurrentUser
                  ? "bg-utility-blue text-white"
                  : "bg-primary-900 text-primary-100"
              }`}
              style={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                maxWidth: "min(calc(100% - 1rem), 28rem)",
              }}
            >
              {message.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const style = document.createElement("style");
style.textContent = `
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes messageIn {
    from {
      opacity: 0;
      transform: translateX(20px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }

  .animate-slideIn {
    animation: slideIn 0.3s ease-out;
  }

  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out;
  }

  .animate-scaleIn {
    animation: scaleIn 0.3s ease-out;
  }

  .animate-messageIn {
    animation: messageIn 0.3s ease-out;
  }
`;
document.head.appendChild(style);

export default ChatMessage;
