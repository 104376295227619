import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Assume these functions are imported from their respective files
import { joinTribes } from '../../api/api';
import { userExistsInTribes } from '../../helpers/utils';
import { openMaximumTribesJoinedModal, openTribeExitedMaximumTimes, closeTribe } from '../../redux/Reducers/modalReducer';

export const useJoinTribe = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({tribeId}) => {
        return joinTribes({ tribe_id: tribeId })},
    onMutate: ({ tribe, user }) => {
      /* if (userExistsInTribes(tribe, user._id)) {
        dispatch(openMaximumTribesJoinedModal());
        throw new Error('User already exists in tribe');
      } */
    },
    onSuccess: (response) => {
      if (response.statusCode === "400") {
        dispatch(openTribeExitedMaximumTimes());
      } else if (response.statusCode === 201) {
        toast.success(response.message);
        dispatch(closeTribe());
        navigate("/myTribes");
        // Invalidate and refetch relevant queries
        queryClient.invalidateQueries(['tribes']);
      } else {
        toast.error(response.error);
      }
    },
    onError: (error) => {
      console.error("Error joining tribe:", error);
      toast.error("Failed to join tribe. Please try again.");
    },
  });
};