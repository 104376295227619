import dayjs from "dayjs";
import { EventArraySchema } from "../Component/Index/Events/event.schema.ts";
import { Category } from "../Component/Index/Events/Events.tsx";
import { MUSIC_GENRES, SPORT_CATEGORIES } from "./ticketmaster-constants.ts";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import axiosInstance from "./interceptor.js";
// temporarily storing various stuff here
// trying to stay within project budget as well as not doing any hasty abstractions until we know what we need

dayjs.extend(utc)
dayjs.extend(timezone)

export type UserLocation = {
  lat: number;
  lng: number;
};

export type EventArguments = {
  location: UserLocation;
  pageParam?: number;
  category: Category;
  genre: MusicGenre;
  time_query: (typeof DATE_RANGE)[number]["value"];
  customDates: { start: Date; end: Date };
  within_miles?: string;
  limit?: number;
};

export const DATE_RANGE = [
  { label: "All", value: "all" },
  { label: "Tonight", value: "tonight" },
  { label: "Tomorrow", value: "tomorrow" },
  { label: "This Weekend", value: "thisWeekend" },
  { label: "Next Weekend", value: "nextWeekend" },
  {
    label: "Custom",
    value: "custom",
  },
] as const;

export type DateRangeOption = (typeof DATE_RANGE)[number]["value"];

interface DateRange {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}



export const generateDateRange = (
  option: Exclude<DateRangeOption, "all" | "custom">
): DateRange => {
  const now = dayjs();
  const currentDay = now.day(); // 0 for Sunday, 6 for Saturday

  switch (option) {
    case "tonight":
      return {
        startDate: now,
        endDate: now.endOf("day"),
      };

    case "tomorrow":
      const tomorrow = now.add(1, "day").startOf("day");
      return {
        startDate: tomorrow,
        endDate: tomorrow.endOf("day"),
      };

    case "thisWeekend":
      let startDate: dayjs.Dayjs;
      let endDate: dayjs.Dayjs;
      
      switch (currentDay) {
        case 5: // Friday
          startDate = now.startOf("day");
          endDate = now.add(2, "day").endOf("day"); // End of Sunday
          break;
        case 6: // Saturday
          startDate = now.startOf("day");
          endDate = now.add(1, "day").endOf("day"); // End of Sunday
          break;
        case 0: // Sunday
          startDate = now.startOf("day");
          endDate = now.endOf("day"); // End of Sunday
          break;
        default: // Any other day
          startDate = now.day(5).startOf("day"); // Start of upcoming Friday
          endDate = now.day(5).add(2, "day").endOf("day"); // End of upcoming Sunday
      }
      
      return { startDate, endDate };

    case "nextWeekend":
      let nextWeekend: dayjs.Dayjs;
      if (currentDay === 0) { // If it's Sunday
        nextWeekend = now.add(5, "day"); // Next Friday
      } else if (currentDay === 6) { // If it's Saturday
        nextWeekend = now.add(6, "day"); // Next Friday
      } else {
        nextWeekend = now.day(5).add(1, "week"); // Next Friday
      }
      return {
        startDate: nextWeekend.startOf("day"),
        endDate: nextWeekend.add(2, "day").endOf("day"), // End of Sunday
      };

    default:
      throw new Error("Invalid date range option");
  }
};
export const NUMBER_OF_EVENTS_PER_PAGE = 50;

export type SportGenres = (typeof SPORT_CATEGORIES)[number];
export type MusicGenre = (typeof MUSIC_GENRES)[number];
export type Genres = SportGenres | MusicGenre;

export const fetchEvents = async ({
  location,
  pageParam = 0,
  category,
  genre,
  time_query,
  customDates,
  within_miles = "50",
  limit = NUMBER_OF_EVENTS_PER_PAGE,
}: EventArguments) => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    within_miles,
    offset: pageParam.toString(),
    event_source: "TicketMaster",
    latitude: location.lat?.toString() ?? undefined,
    longitude: location.lng?.toString() ?? undefined,
  });

  if (time_query !== "all") {
    if (time_query === "custom") {
      params.append(
        "after_date",
        dayjs(customDates.start).toString()
      );
      params.append(
        "before_date",
        dayjs(customDates.end).endOf("day").toString()
      );
    } else {
      const dates = generateDateRange(time_query);
      params.append(
        "after_date",
        dates.startDate.toDate().toString()
      );
      params.append(
        "before_date",
        dayjs(dates.endDate).endOf("day").toDate().toString()
      );
    }
  } else {

    params.append("after_date", dayjs().toDate().toString());
    params.append(
      "before_date",
      dayjs().add(1, "year").endOf("date").toDate().toString()
    );
  }

  if (category !== "all") {
    params.append("event_type", category);
  }

  if (genre !== "All" && category !== "sports") {
    params.append("event_genre", genre);
  }

  if (category === "sports" && genre !== "All") {
    params.append("sport", genre);
  }

  const response = await fetch(
    `https://api.tribe-in.com/api/v1/events?${params}`
  );
  const json = await response.json();

  try {
    return EventArraySchema.parse(json?.events);
  } catch (error) {
    console.error(error);
  }
};

export async function getFestivals() { 
  const response = await axiosInstance.get("/events/festivals")
  return response.data
}